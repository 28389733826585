/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/function-component-definition */
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { MenuItem, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import tw, { css, styled } from "twin.macro"; // eslint-disable-line no-unused-vars
import {
  AlarmOnRounded,
  AssignmentIndTwoTone,
  EditRounded,
  InfoTwoTone,
  // Print,
  ScheduleTwoTone,
} from "@mui/icons-material";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import { differenceInCalendarDays } from "date-fns";
import { pickBy } from "lodash";

import {
  ContentWithUtilsBar,
  ErrorDialog,
  JimButton,
  JimDialog,
  LoadingDialog,
  LoadingTile,
  TextButton,
  TilePrim,
} from "../components/util-components";
import {
  drawerWidthSm,
  mq,
  timeFilterObjPastDayjs,
} from "../utils/global-consts";
import { PageTitleContext } from "../utils/pagetitle-provider";
import withRoles from "../utils/with-roles";
import { formatDE, hoursToHoursMin } from "../utils/util-functions";
import { ScreenSizeContext } from "../utils/screensize-provider";
import JobFinishDialog from "../components/JobFinishDialog";
import JobDetails from "../components/JobDetails";
// import { UserRolesContext } from "../utils/userroles-provider"
import JimHelmet from "../components/JimHelmet";
import UtilsBar from "../layout/UtilsBar";
import { UserRolesContext } from "../utils/userroles-provider";

// const LIST_POOLS_QUERY = gql`
//   query ListPools($facility: [ID]) {
//     listPools(facility: $facility) {
//       id
//       title
//       description
//       workers {
//         id
//         first_name
//         last_name
//       }
//     }
//   }
// `

const GET_POOL_QUERY = gql`
  query getPool($id: ID!) {
    getPool(id: $id) {
      id
      title
      description
      workers {
        id
        first_name
        last_name
      }
    }
  }
`;

const DEPT_JOBINSTANCES_QUERY = gql`
  query ListDeptJobInstances(
    $department: [ID]
    $pool: [ID]
    $worker: [ID]
    $startsBefore: Float
    $startsAfter: Float
    $endsBefore: Float
    $endsAfter: Float
  ) {
    listJobInstances(
      department: $department
      pool: $pool
      worker: $worker
      startsBefore: $startsBefore
      startsAfter: $startsAfter
      endsBefore: $endsBefore
      endsAfter: $endsAfter
    ) {
      id
      group_id
      job {
        id
        title
      }
      description
      status
      department {
        id
        name
        facility {
          name
        }
      }
      start_time
      end_time
      actual_start_time
      actual_end_time
      length
      pay
      worker {
        first_name
        last_name
      }
      pool {
        id
      }
      instant_book
    }
  }
`;

const FINISH_JOBINSTANCE = gql`
  mutation FinishJobInstance($id: ID!, $startTime: Float, $endTime: Float) {
    finishJobInstance(id: $id, startTime: $startTime, endTime: $endTime) {
      id
      actual_start_time
      actual_end_time
    }
  }
`;

const EDIT_JOBINSTANCE = gql`
  mutation EditJobInstance(
    $jobInstance: ID!
    $actual_start_time: Float
    $actual_end_time: Float
    $worker: ID
    $start_time: Float
    $end_time: Float
    $description: String
    $pay: Float
    $length: Float
    $status: String
  ) {
    editJobInstance(
      jobInstance: $jobInstance
      actual_start_time: $actual_start_time
      actual_end_time: $actual_end_time
      worker: $worker
      start_time: $start_time
      end_time: $end_time
      description: $description
      pay: $pay
      length: $length
      status: $status
    ) {
      id
      actual_start_time
      actual_end_time
      worker {
        id
        first_name
        last_name
      }
      start_time
      end_time
      description
      pay
      length
      status
    }
  }
`;

const JTTitle = tw.div`text-prim-500 tracking-wider`;

const JobTile = ({
  data,
  jobInstAll,
  withDate,
  refetch,
  refetchVars,
  sameAsAbove,
  workersArr,
}) => {
  const screen = useContext(ScreenSizeContext);
  const [showFinishDialog, setShowFinishDialog] = useState(false);
  const [showAssignDialog, setShowAssignDialog] = useState(false);
  const [workerToAssign, setWorkerToAssign] = useState(
    workersArr[0] ? workersArr[0].id : ""
  );
  const [showJobDetails, setShowJobDetails] = useState(false);
  const [editMulti, setEditMulti] = useState(false);
  const [finishJobInstance, { loading: finishLoading, error: finishError }] =
    useMutation(FINISH_JOBINSTANCE);
  const [editJobInstance, { loading: editLoading, error: editError }] =
    useMutation(EDIT_JOBINSTANCE);
  const allJobsOpen =
    jobInstAll.filter(
      jobInst => jobInst.group_id === data.group_id && jobInst.status !== "OPEN"
    ).length === 0;

  const handleClickOnTile = e => {
    e.stopPropagation();
    setShowJobDetails(true);
  };

  const handleClickOnTime = e => {
    e.stopPropagation();
    setShowFinishDialog(true);
  };

  const handleTimeEditMulti = e => {
    e.stopPropagation();
    if (!allJobsOpen) {
      setEditMulti(true);
      setShowFinishDialog(true);
    }
  };

  const handleFinish = (e, actualTimes) => {
    e.preventDefault();
    const editOrFinish = jobInst => {
      if (
        jobInst.actual_start_time &&
        (jobInst.actual_start_time !== actualTimes.start.valueOf() ||
          jobInst.actual_end_time !== actualTimes.end.valueOf())
      ) {
        editJobInstance({
          variables: {
            jobInstance: jobInst.id,
            actual_start_time: actualTimes.start.valueOf(),
            actual_end_time: actualTimes.end.valueOf(),
          },
        }).then(() => {
          refetch(refetchVars);
          setShowFinishDialog(false);
        });
      } else if (!jobInst.actual_start_time) {
        finishJobInstance({
          variables: {
            id: jobInst.id,
            startTime: actualTimes.start.valueOf(),
            endTime: actualTimes.end.valueOf(),
          },
        }).then(() => {
          refetch(refetchVars);
          setShowFinishDialog(false);
        });
      }
    };

    if (editMulti) {
      jobInstAll.forEach(instInArr => {
        if (
          instInArr.group_id === data.group_id &&
          instInArr.status !== "OPEN"
        ) {
          editOrFinish(instInArr);
        }
      });
    } else {
      editOrFinish(data);
    }
  };

  const handleAssign = e => {
    e.preventDefault();
    editJobInstance({
      variables: {
        jobInstance: data.id,
        worker: workerToAssign,
        status: "ASSIGNED",
      },
    }).then(() => {
      refetch(refetchVars);
      setShowAssignDialog(false);
    });
  };

  return (
    <>
      {!sameAsAbove && (
        <div tw="flex">
          <div tw="flex pr-2 py-4 w-10 text-prim-500 text-lg font-semibold opacity-70">
            {withDate && (
              <div tw="flex flex-col items-center">
                <div tw="text-xl font-bold">
                  {formatDE(new Date(data.start_time), "dd")}
                </div>
                <div tw="text-xs tracking-tighter">
                  {formatDE(new Date(data.start_time), "LLL").toUpperCase()}
                </div>
              </div>
            )}
          </div>
          <div
            tw="flex flex-grow rounded-md shadow-md cursor-pointer mt-4 bg-white"
            onClick={handleClickOnTile}
          >
            <div tw="flex flex-col flex-grow  p-2">
              <JTTitle>{data.job.title}</JTTitle>
              <div tw="flex flex-col pt-2 space-y-1 md:(flex-row space-y-0 space-x-4)">
                <div tw="flex items-center">
                  <ScheduleTwoTone tw="mr-2 text-lg" />
                  {`${formatDE(
                    new Date(data.start_time),
                    "HH:mm"
                  )} - ${formatDE(
                    new Date(data.end_time),
                    "HH:mm"
                  )} (${hoursToHoursMin(
                    (data.end_time - data.start_time) / 3600000
                  )})`}
                </div>
                {data.description && screen.smUp && (
                  <div tw="flex items-center w-24 md:w-48">
                    <InfoTwoTone tw="mr-2 text-lg" />
                    <div tw="truncate">{data.description}</div>
                  </div>
                )}
              </div>
            </div>
            <div tw="flex items-center p-1">
              <TextButton onClick={handleTimeEditMulti} disabled={allJobsOpen}>
                für alle
                <EditRounded tw="ml-2 text-base" />
              </TextButton>
            </div>
          </div>
        </div>
      )}
      <div tw="flex h-14">
        <div tw="flex w-10" />
        <div tw="flex flex-grow items-center justify-between rounded-md shadow-md mt-1 bg-white">
          <div tw="flex px-4">
            <div css={[tw`flex items-center`, !data.worker && tw`italic`]}>
              <AssignmentIndTwoTone tw="mr-2 text-lg" />
              {data.worker
                ? `${data.worker.first_name[0]}. ${data.worker.last_name}`
                : "unbesetzt"}
            </div>
          </div>
          {data.actual_start_time ? (
            <div
              tw="relative flex flex-col justify-center self-stretch text-base cursor-pointer hover:text-prim-500 transition duration-200"
              onClick={handleClickOnTime}
            >
              <div>
                {hoursToHoursMin(
                  (data.actual_end_time - data.actual_start_time) / 3600000
                )}
              </div>
              <div tw="text-xs italic  pr-6">{`${formatDE(
                new Date(data.actual_start_time),
                "HH:mm"
              )} - ${formatDE(new Date(data.actual_end_time), "HH:mm")}`}</div>
              <div tw="absolute w-6 h-6 top-1 right-1">
                <EditRounded tw="text-base" />
              </div>
            </div>
          ) : (
            <div tw="px-2">
              {data.worker ? (
                <JimButton variant="cancel" onClick={handleClickOnTime}>
                  <AlarmOnRounded />
                </JimButton>
              ) : (
                <TextButton onClick={() => setShowAssignDialog(true)}>
                  JiM zuweisen
                </TextButton>
              )}
            </div>
          )}
        </div>
      </div>
      <JobFinishDialog
        open={showFinishDialog}
        handleClose={() => setShowFinishDialog(false)}
        jobInst={data}
        handleConfirm={handleFinish}
      />
      <JobDetails
        jobInst={data}
        jobInstAll={jobInstAll}
        open={showJobDetails}
        handleClose={() => setShowJobDetails(false)}
        withEdit
        refetch={refetch}
        refetchVars={refetchVars}
      />
      <JimDialog
        open={showAssignDialog}
        handleClose={() => setShowAssignDialog(false)}
        title="JiM zuweisen"
        closable
      >
        <div tw="flex space-x-4 items-center">
          <TextField
            tw="w-full"
            id="select-jim-assign"
            value={workerToAssign}
            label="JiM"
            select
            variant="outlined"
            onChange={e => setWorkerToAssign(e.target.value)}
          >
            {workersArr[0] &&
              workersArr.map(worker => (
                <MenuItem
                  key={worker.id}
                  value={worker.id}
                >{`${worker.first_name[0]}. ${worker.last_name}`}</MenuItem>
              ))}
          </TextField>
          <JimButton variant="primary" onClick={handleAssign}>
            zuweisen
          </JimButton>
        </div>
      </JimDialog>
      <LoadingDialog open={finishLoading || editLoading} />
      {(finishError || editError) && <ErrorDialog />}
    </>
  );
};
JobTile.propTypes = {
  data: shape({
    start_time: number,
    end_time: number,
    length: number,
    job: shape({
      title: string,
    }),
  }).isRequired,
  jobInstAll: arrayOf(
    shape({
      start_time: number,
      end_time: number,
      length: number,
      job: shape({
        title: string,
      }),
    })
  ).isRequired,
  withDate: bool.isRequired,
  sameAsAbove: bool.isRequired,
  refetch: func.isRequired,
  refetchVars: shape().isRequired,
  workersArr: arrayOf(shape()).isRequired,
};

const SumDivWrapper = styled.div(() => [
  tw`w-full mx-auto fixed bottom-0 pr-4 mr-60 lg:px-0`,
  `${mq.sm} {
    width: calc(100% - ${drawerWidthSm}px);
  }`,
  tw`sm:max-w-4xl xl:max-w-5xl`,
]);

const JobsPastFacility = ({ facilityID }) => {
  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => setPageTitle("Abgeschlossene Einsätze"), []);
  const { userFacilitiesByID } = useContext(UserRolesContext);
  const [deptsSel, setDeptsSel] = useState(
    userFacilitiesByID[facilityID].departments.map(dept => dept.id)
  );
  const [poolsSel, setPoolsSel] = useState(
    userFacilitiesByID[facilityID].pools.map(pool => pool.id)
  );
  const [jimsSel, setJimsSel] = useState(
    userFacilitiesByID[facilityID].users.map(user => user.user.id)
  );
  const [timesSel, setTimesSel] = useState(timeFilterObjPastDayjs);

  const [
    listJobInstances,
    { data: jobInstData, loading: jobInstLoading, refetch },
  ] = useLazyQuery(DEPT_JOBINSTANCES_QUERY);
  const [getPool, { data: singlePoolData }] = useLazyQuery(GET_POOL_QUERY);
  // const [listPools, { data: multiPoolData }] = useLazyQuery(LIST_POOLS_QUERY)

  useEffect(() => {
    listJobInstances({
      variables: pickBy({
        worker: typeof jimsSel === "string" ? jimsSel : null,
        department: deptsSel,
        pool: poolsSel,
        endsAfter: timesSel.left.valueOf(),
        startsBefore: timesSel.right.valueOf(),
      }),
    });
  }, [deptsSel, poolsSel, jimsSel, timesSel]);
  const [isRed, setIsRed] = useState(false);

  useEffect(() => {
    if (jobInstData) {
      let res = false;
      for (let i = 0; i < jobInstData.listJobInstances.length; i += 1) {
        if (
          !jobInstData.listJobInstances[i].actual_start_time ||
          !jobInstData.listJobInstances[i].actual_end_time
        ) {
          res = true;
          break;
        }
      }
      setIsRed(res);
    }
  }, [jobInstData]);

  useEffect(() => {
    if (typeof poolsSel === "string") {
      getPool({ variables: { id: poolsSel } });
    }
    // else {
    //   listPools({ variables: { facility: facilityID } })
    // }
  }, [poolsSel]);

  const getSumDiv = durationArr => {
    let sum = 0;
    durationArr.forEach(dur => {
      sum += dur;
    });
    return `${Math.floor(sum / 3600000)}h${`0${Math.round(
      (sum - Math.floor(sum / 3600000) * 3600000) / 60000
    )}`.slice(-2)}`;
  };

  return (
    <>
      <JimHelmet path={`employer/facility/${facilityID}/jobs-past`} />
      <UtilsBar
        facilityID={facilityID}
        showDept
        showPools
        showJiMs
        showTime
        past
        // btn={
        //   <TextButton tw="self-center" onClick={() => window.print()}>
        //     <Print />
        //   </TextButton>
        // }
        setDeptsSel={setDeptsSel}
        setPoolsSel={setPoolsSel}
        setJimsSel={setJimsSel}
        setTimesSel={setTimesSel}
      />
      <ContentWithUtilsBar>
        {jobInstData &&
          jobInstData.listJobInstances &&
          jobInstData.listJobInstances[0] && (
            <>
              {/* <div tw="overflow-y-auto p-2"> */}
              {jobInstData.listJobInstances.map((jobInst, ind) => (
                <JobTile
                  key={`job-tile-${jobInst.id}`}
                  data={jobInst}
                  withDate={
                    ind === 0 ||
                    (ind > 0 &&
                      differenceInCalendarDays(
                        new Date(jobInst.start_time),
                        new Date(
                          jobInstData.listJobInstances[ind - 1].start_time
                        )
                      ) > 0)
                  }
                  sameAsAbove={
                    ind > 0 &&
                    jobInst.start_time ===
                      jobInstData.listJobInstances[ind - 1].start_time &&
                    jobInst.end_time ===
                      jobInstData.listJobInstances[ind - 1].end_time &&
                    jobInst.job.id ===
                      jobInstData.listJobInstances[ind - 1].job.id &&
                    jobInst.description ===
                      jobInstData.listJobInstances[ind - 1].description
                  }
                  refetch={refetch}
                  refetchVars={pickBy({
                    worker: typeof jimsSel === "string" ? jimsSel : null,
                    department: deptsSel,
                  })}
                  jobInstAll={jobInstData.listJobInstances}
                  workersArr={
                    poolsSel.length === 1 &&
                    singlePoolData &&
                    singlePoolData.getPool
                      ? singlePoolData.getPool.workers
                      : userFacilitiesByID[facilityID].users.map(
                          user => user.user
                        )
                  }
                />
              ))}
              {/* </div> */}
              <div tw="h-16" />
              <SumDivWrapper>
                <div tw="flex flex-grow rounded-t-md shadow-upper-md bg-white p-2">
                  <div tw="flex justify-end items-center p-2 flex-grow italic">
                    {`${timesSel.left.format(
                      "D.MM."
                    )} bis ${timesSel.right.format("DD.MM.")}:`}
                  </div>
                  <div tw="flex flex-col">
                    <div tw="flex items-center justify-center text-base px-1 font-semibold">
                      {`geplant: ${getSumDiv(
                        jobInstData.listJobInstances.map(
                          jobInst => jobInst.end_time - jobInst.start_time
                        )
                      )}`}
                    </div>
                    <div
                      css={[
                        tw`flex items-center justify-center text-base px-1 font-semibold text-green-600`,
                        isRed && tw`text-red-500`,
                      ]}
                    >
                      {`${isRed ? "(" : ""}tatsächlich: ${getSumDiv(
                        jobInstData.listJobInstances.map(jobInst =>
                          jobInst.actual_start_time && jobInst.actual_end_time
                            ? jobInst.actual_end_time -
                              jobInst.actual_start_time
                            : jobInst.end_time - jobInst.start_time
                        )
                      )}${isRed ? ")" : ""}`}
                    </div>
                  </div>
                </div>
              </SumDivWrapper>
            </>
          )}

        {!jobInstLoading && jobInstData && !jobInstData.listJobInstances[0] && (
          <TilePrim>
            <div>
              Für deine Suche konnten wir keine abgeschlossenen Einsätze finden.
            </div>
          </TilePrim>
        )}
        {jobInstLoading && <LoadingTile />}
      </ContentWithUtilsBar>
    </>
  );
};
JobsPastFacility.propTypes = {
  facilityID: string.isRequired,
};

export default withRoles(JobsPastFacility, "EMPLOYER");
