/* eslint-disable react/function-component-definition */
import { gql, NetworkStatus, useMutation, useQuery } from "@apollo/client";
import { TextField, Autocomplete, Switch } from "@mui/material";
import { EmailRounded, InfoOutlined, PhoneRounded } from "@mui/icons-material";
import { shape, string } from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro"; // eslint-disable-line no-unused-vars
import { navigate } from "gatsby";

import {
  ContentWithUtilsBar,
  ErrorDialog,
  JimButton,
  JimDialog,
  LoadingDialog,
  LoadingTile,
  SupportTile,
  TextButton,
  TilePrim,
  UtilsBarWrapper,
} from "../../components/util-components";
import { PageTitleContext } from "../../utils/pagetitle-provider";
import withRoles from "../../utils/with-roles";
import JimHelmet from "../../components/JimHelmet";
import { UserRolesContext } from "../../utils/userroles-provider";
import LimitReachedDialog from "../../components/page-components/pools-jims/LimitReachedDialog";

const GET_POOL = gql`
  query GetPool($id: ID!) {
    getPool(id: $id) {
      id
      title
      description
      workers {
        id
        first_name
        last_name
        phone
        email
      }
      facility {
        id
      }
    }
  }
`;

const REMOVE_WORKER_MUTATION = gql`
  mutation RemoveWorker($pool: ID!, $user: ID!) {
    removeWorker(pool: $pool, user: $user) {
      id
    }
  }
`;

const ADD_WORKER_MUTATION = gql`
  mutation AddWorker($pool: ID!, $user: ID!) {
    addWorker(pool: $pool, user: $user) {
      id
    }
  }
`;

const WorkerTile = ({ worker, poolData }) => {
  const [
    removeWorker,
    { loading: removeWorkerLoading, error: removeWorkerError },
  ] = useMutation(REMOVE_WORKER_MUTATION);
  const [awaitConfirmRemove, setAwaitConfirmRemove] = useState(false);

  const handleClickOnRemove = e => {
    e.stopPropagation();
    setAwaitConfirmRemove(true);
  };

  const handleRemoveWorker = e => {
    e.stopPropagation();
    removeWorker({
      variables: { pool: poolData.getPool.id, user: worker.id },
      refetchQueries: [
        { query: GET_POOL, variables: { id: poolData.getPool.id } },
      ],
    });
    setAwaitConfirmRemove(false);
  };

  return (
    <>
      <TilePrim key={`worker-tile-${worker.last_name}`} tw="divide-y-0">
        <div tw="flex items-center justify-between">
          <div tw="text-lg font-semibold">{`${worker.first_name} ${worker.last_name}`}</div>
          <TextButton onClick={handleClickOnRemove}>entfernen</TextButton>
        </div>
        <div tw="flex flex-col sm:(flex-row space-x-16) pt-2">
          <TextButton>
            <a tw="flex items-center" href={`tel:${worker.phone}`}>
              <PhoneRounded tw="text-xl mr-2" />
              {worker.phone}
            </a>
          </TextButton>
          <TextButton>
            <a tw="flex items-center" href={`mailto:${worker.email}`}>
              <EmailRounded tw="text-xl mr-2" />
              {worker.email}
            </a>
          </TextButton>
        </div>
      </TilePrim>
      <JimDialog
        open={awaitConfirmRemove}
        handleClose={() => setAwaitConfirmRemove(false)}
        tw="items-center w-96 text-center"
      >
        <div tw="text-lg">
          <strong>{`${worker.first_name} ${worker.last_name}`}</strong> wirklich
          entfernen?
        </div>
        <div tw="flex items-center space-x-4">
          <TextButton onClick={() => setAwaitConfirmRemove(false)}>
            abbr.
          </TextButton>
          <JimButton variant="cancel" onClick={handleRemoveWorker}>
            ja, entfernen
          </JimButton>
        </div>
      </JimDialog>
      <LoadingDialog open={removeWorkerLoading} />
      {removeWorkerError && <ErrorDialog />}
    </>
  );
};
WorkerTile.propTypes = {
  worker: shape().isRequired,
  poolData: shape().isRequired,
};

const PoolJims = ({ facilityID, poolID }) => {
  const {
    data: poolData,
    loading,
    error,
    networkStatus,
  } = useQuery(GET_POOL, {
    variables: { id: poolID },
    notifyOnNetworkStatusChange: true,
  });
  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(
    () =>
      setPageTitle(
        poolData && poolData.getPool ? poolData.getPool.title : "..."
      ),
    [poolData]
  );
  const { userFacilitiesByID } = useContext(UserRolesContext);
  const facility = userFacilitiesByID[facilityID];
  // const limitReached =
  //   facility.active_users <=
  //   facility.billing.current_subscription.plan.monthly_active_users;
  const [limitReached, setLimitReached] = useState(false);

  const [addWorker, { loading: addWorkerLoading, error: addWorkerError }] =
    useMutation(ADD_WORKER_MUTATION, {
      refetchQueries: [{ query: GET_POOL, variables: { id: poolID } }],
    });
  const [showLimitReachedDialog, setShowLimitReachedDialog] = useState(false);
  const [showAddWorkerField, setShowAddWorkerField] = useState(false);
  const [jimToAdd, setJimToAdd] = useState(null);
  // const [filteredJims, setFilteredJims] = useState([]);

  const handleAddWorker = e => {
    e.preventDefault();
    e.stopPropagation();

    addWorker({
      variables: {
        pool: poolData.getPool.id,
        user: jimToAdd.user.id,
      },
    }).then(() => setShowAddWorkerField(false));
  };

  // const handleJimsSearch =({target}) => {
  //   const searchString = target.value.toLowerCase();
  //   setFilteredJims(
  //     facility.users.filter(userObj =>
  //       `${userObj.user.first_name} ${userObj.user.last_name}`
  //         .toLowerCase()
  //         .includes(searchString)
  //     )
  //   );
  // }

  const handleAddWorkerBtnClick = () => {
    if (limitReached) {
      setShowLimitReachedDialog(true);
    } else {
      setShowAddWorkerField(true);
    }
  };

  return (
    <>
      <JimHelmet
        path={`employer/facility/${facilityID}/jims/${
          poolData && poolData.getPool ? `${poolData.getPool.id}` : "..."
        }`}
      />
      <UtilsBarWrapper tw="justify-between">
        {poolData && poolData.getPool && showAddWorkerField && (
          <form
            id="add-worker-form"
            onSubmit={handleAddWorker}
            tw="flex items-center space-x-4"
          >
            <Autocomplete
              id="jim-search-autocomplete"
              options={facility.users.filter(
                userObj =>
                  userObj.role === "WORKER" &&
                  !poolData.getPool.workers
                    .map(worker => worker?.id)
                    .includes(userObj.user.id)
              )}
              getOptionLabel={userObj =>
                `${userObj.user.first_name} ${userObj.user.last_name}`
              }
              renderInput={params => (
                <TextField
                  {...params}
                  tw="w-64"
                  label="JiM"
                  variant="outlined"
                />
              )}
              value={jimToAdd}
              onChange={(ev, newVal) => setJimToAdd(newVal)}
              renderOption={(props, userObj) => (
                <li {...props}>
                  <div tw="flex flex-col">
                    <div>{`${userObj.user.first_name} ${userObj.user.last_name}`}</div>
                    <div tw="italic text-sm text-gray-500">
                      {userObj.user.email}
                    </div>
                  </div>
                </li>
              )}
            />
            <TextButton onClick={() => setShowAddWorkerField(false)} tw="mt-1">
              abbr.
            </TextButton>
            <JimButton
              tw="mt-1"
              variant="primary"
              type="submit"
              form="add-worker-form"
              disabled={!jimToAdd}
            >
              bestätigen
            </JimButton>
          </form>
        )}
        {!showAddWorkerField && (
          <JimButton
            variant="primary"
            onClick={handleAddWorkerBtnClick}
            tw="mt-1"
          >
            JiM hinzufügen
          </JimButton>
        )}
        {/* <div>{showFindUserError ? "error" : "no error"}</div>
          <div>{typeof userFromEmail}</div>
          <div>{userFromEmail && userFromEmail.listUsers.length}</div> */}
        {/* <div tw="flex flex-col self-end p-1 border-dashed border-2 border-black bg-yellow-300 rounded-md text-sm">
          <div>DEV ONLY</div>
          <div>JiM Limit erreicht?</div>
          <Switch
            color="primary"
            checked={limitReached}
            onChange={e => setLimitReached(e.target.checked)}
          />
        </div> */}
      </UtilsBarWrapper>
      <ContentWithUtilsBar>
        {showAddWorkerField &&
          facility.users.filter(
            userObj => userObj.role === "WORKER" && !poolData.getPool.workers
          ).length === 0 && (
            <div tw="flex p-1 bg-red-100 rounded-md">
              <InfoOutlined tw="text-xl align-middle" />
              <div tw="flex flex-col items-start">
                <span tw="pl-2">
                  Um JiMs zum Pool hinzuzufügen, musst du sie zunächst der
                  Einrichtung hinzufügen.
                </span>
                <TextButton
                  onClick={() =>
                    navigate(
                      `/employer/${facilityID}/facility-settings/manage-jims`
                    )
                  }
                >
                  Jetzt JiMs hinzufügen
                </TextButton>
              </div>
            </div>
          )}
        {poolData &&
          poolData.getPool &&
          poolData.getPool.workers[0] &&
          poolData.getPool.workers.map(
            worker =>
              worker && <WorkerTile worker={worker} poolData={poolData} />
          )}
        {poolData && poolData.getPool && !poolData.getPool.workers[0] && (
          <TilePrim>Diesem Pool gehört aktuell niemand an.</TilePrim>
        )}
        {loading && <LoadingTile />}
        {error && <SupportTile />}
        <LoadingDialog
          open={addWorkerLoading || networkStatus === NetworkStatus.refetch}
        />
        {addWorkerError && (
          <ErrorDialog
            errorMsg={
              addWorkerError.message === "User is already in pool!" &&
              "Die ausgewählte Person ist bereits Teil des Pools"
            }
          />
        )}
        <LimitReachedDialog
          open={showLimitReachedDialog}
          handleClose={() => setShowLimitReachedDialog(false)}
          facilityID={facilityID}
        />
      </ContentWithUtilsBar>
    </>
  );
};
PoolJims.propTypes = {
  facilityID: string.isRequired,
  poolID: string.isRequired,
};

export default withRoles(PoolJims, "EMPLOYER");
