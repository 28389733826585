/* eslint-disable react/function-component-definition */
import React from "react";
import { Router } from "@reach/router"; // eslint-disable-line import/no-unresolved
import tw from "twin.macro"; // eslint-disable-line no-unused-vars
// import FacilityDashboard from "../pages-employer/facility"
import Availabilities from "../pages-employer/availabilities";
import JobsPast from "../pages-employer/jobs-past";
import JobsCurrent from "../pages-employer/jobs-current";
import CreateJob from "../pages-employer/create-job";
// import JobsCurrentDept from "../pages-employer/dept/jobs-current"
// import JobsPastDept from "../pages-employer/dept/jobs-past"
// import CreateJobDept from "../pages-employer/dept/create-job"
// import DeptDashboard from "../pages-employer/dept"
import EditFacility from "../pages-employer/facility-settings/edit-facility";
import JobTemplates from "../pages-employer/facility-settings/job-templates";
import FacilityPools from "../pages-employer/pools";
import PoolJims from "../pages-employer/pools/jims";
import ManageJims from "../pages-employer/facility-settings/manage-jims";

import { TilePrim } from "../components/util-components";

const Default = () => (
  <TilePrim tw="items-center justify-center">
    Bitte wähle eine Abteilung oder Einrichtung aus dem Menü.
  </TilePrim>
);

const DefaultFacilitySettings = ({ children }) => children;

const Employer = () => (
  <Router basepath="/employer/:facilityID">
    {/* <FacilityDashboard path="/facility/:facilityID" /> */}
    <Availabilities path="/availabilities" />
    <JobsPast path="/jobs-past" />
    <JobsCurrent path="/jobs-current" />
    <CreateJob path="/create-job" />
    {/* <DeptDashboard path="/dept/:deptID" /> */}
    {/* <JobsCurrentDept path="/dept/:deptID/jobs-current" /> */}
    {/* <JobsPastDept path="/dept/:deptID/jobs-past" /> */}
    {/* <CreateJobDept path="/dept/:deptID/create-job" /> */}
    <DefaultFacilitySettings path="/facility-settings">
      <EditFacility path="/edit" />
      <JobTemplates path="/job-templates" />
      <FacilityPools path="/pools" />
      <PoolJims path="/jims/:poolID" />
      <ManageJims path="/manage-jims" />
    </DefaultFacilitySettings>
    <Default path="/" />
  </Router>
);

export default Employer;
