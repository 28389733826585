/* eslint-disable camelcase, react/function-component-definition */
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { Popover, Skeleton } from "@mui/material";
import { arrayOf, bool, node, number, shape, string } from "prop-types";
import React, { useContext, useState } from "react";
import tw, { styled } from "twin.macro"; // eslint-disable-line no-unused-vars
import { UserRolesContext } from "../../../utils/userroles-provider";
import { arrayCreator } from "../../../utils/util-functions";

import {
  ErrorDialog,
  JimButton,
  JimDialog,
  LoadingDialog,
  TextButton,
  TilePrim,
} from "../../util-components";

const GET_STRIPE_CHECKOUT_SESSION = gql`
  query GetStripeCheckoutSession(
    $facility: ID!
    $price: ID!
    $success_url: String
    $cancel_url: String
  ) {
    getStripeCheckoutSession(
      facility: $facility
      price: $price
      success_url: $success_url
      cancel_url: $cancel_url
    ) {
      id
      url
    }
  }
`;

const GET_STRIPE_PORTAL_SESSION = gql`
  query GetStripePortalSession($facility: ID!, $return_url: String) {
    getStripePortalSession(facility: $facility, return_url: $return_url) {
      id
      url
    }
  }
`;

const LIST_SUBSCRIPTION_PLANS = gql`
  query ListSubscriptionPlans($status: String) {
    listSubscriptionPlans(status: $status) {
      id
      title
      description
      stripe_id
      status
      monthly_active_users
      price
    }
  }
`;

const ProductTile = ({ title, jimLimit, price, description, btn }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverID = anchorEl ? "active-jims-expl" : undefined;

  return (
    <TilePrim tw="items-center text-center justify-between w-full h-64 my-0 border border-gray-200">
      <div>
        <h3 tw="text-xl font-semibold mb-1">{title}</h3>
        <p tw="text-sm text-gray-500">{description && description}</p>
      </div>
      <div>
        {jimLimit && (
          <>
            <div tw="flex items-center text-prim-500">
              <p tw="cursor-pointer">
                <button
                  type="button"
                  onClick={e => setAnchorEl(e.currentTarget)}
                >
                  bis zu <span tw="font-semibold">{jimLimit}</span> aktive JiMs
                </button>
              </p>
              {/* <HelpOutlineRounded
                tw="text-lg ml-1 cursor-pointer text-prim-500"
                onClick={e => setAnchorEl(e.currentTarget)}
              /> */}
              <Popover
                id={popoverID}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <div tw="flex flex-col space-y-2 max-w-2xs p-2">
                  <div tw="font-semibold bg-gradient-to-bl from-green-400 to-blue-500 bg-clip-text text-transparent">
                    Aktive JiMs
                  </div>
                  <p>
                    JiMs sind aktiv, sobald sie Mitglied eines Pools sind.
                    Mitglieder mehrerer Pools werden nur einfach gezählt.
                  </p>
                </div>
              </Popover>
            </div>
            <div>Unbegrenzt Jobs</div>
            <div>Beliebig viele Pools</div>
          </>
        )}
      </div>
      <div tw="text-lg font-semibold">
        {price && `${price / 100}€ pro Monat`}
      </div>
      {btn}
    </TilePrim>
  );
};
ProductTile.propTypes = {
  title: string.isRequired,
  jimLimit: string,
  price: number,
  description: string,
  btn: node.isRequired,
};
ProductTile.defaultProps = {
  jimLimit: null,
  price: null,
  description: null,
};

const ProductTileLoading = () => (
  <TilePrim tw="items-center text-center justify-between w-full h-64 my-0 border border-gray-200">
    <div tw="flex flex-col items-center">
      <div tw="text-xl font-semibold mb-1">
        <Skeleton width={90} />
      </div>
      <div tw="text-sm text-gray-500">
        <Skeleton width={130} />
      </div>
    </div>
    <div tw="flex flex-col items-center">
      <div>
        <Skeleton width={160} />
      </div>
      <div>
        <Skeleton width={130} />
      </div>
      <div>
        <Skeleton width={140} />
      </div>
    </div>
    <div tw="text-lg font-semibold">
      <Skeleton width={130} />
    </div>
    <div tw="h-8" />
  </TilePrim>
);

const SubscriptionPlansDialog = ({
  facilityID,
  subscrPlansLoading,
  subscrPlansList,
  subscrPlansError,
}) => {
  const [
    getStripeCheckoutSession,
    {
      data: checkoutSessionData,
      loading: checkoutSessionLoading,
      error: checkoutSessionError,
    },
  ] = useLazyQuery(GET_STRIPE_CHECKOUT_SESSION);

  const handleCheckoutClick = (e, price) => {
    e.preventDefault();
    e.stopPropagation();

    getStripeCheckoutSession({
      variables: {
        facility: facilityID,
        price,
        success_url: window.location.href,
        cancel_url: window.location.href,
      },
    }).then(({ data }) => {
      window.location.href = data.getStripeCheckoutSession.url;
    });
  };

  return (
    <>
      {/* <div>
        <p>Wähle den passenden Plan für deine Bedürfnisse.</p>
        <p>
          Aktive JiMs sind die, die sich in mindestens einem Pool befinden.
          Mitglieder mehrerer Pools werden nur einfach gezählt.
        </p>
        <p>
          In allen unseren Plänen kannst du{" "}
          <span tw="font-semibold">unbegrenzt Jobs</span> erstellen und{" "}
          <span tw="font-semibold">beliebig viele Pools</span> verwalten.
        </p>
      </div> */}
      <div tw="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-4">
        {subscrPlansLoading &&
          arrayCreator(1, 4, 1).map(() => <ProductTileLoading />)}
        {subscrPlansList &&
          subscrPlansList.listSubscriptionPlans &&
          !subscrPlansError && (
            <>
              {subscrPlansList.listSubscriptionPlans
                .sort((a, b) => a.monthly_active_users - b.monthly_active_users)
                .map(subscrPlan => (
                  <ProductTile
                    title={subscrPlan.title}
                    jimLimit={subscrPlan.monthly_active_users}
                    price={subscrPlan.price}
                    description={subscrPlan.description}
                    btn={
                      <JimButton
                        variant="primary"
                        onClick={e =>
                          handleCheckoutClick(e, subscrPlan.stripe_id)
                        }
                      >
                        auswählen
                      </JimButton>
                    }
                  />
                ))}
              {/* <ProductTile
                title="JiM Enterprise"
                description="Für noch größere Pläne."
                btn={
                  <a
                    tw="flex justify-center items-center px-2 py-1 justify-self-end rounded font-semibold tracking-wide text-white transition duration-200 select-none bg-prim-400 hover:bg-prim-600"
                    href="mailto:kontakt@morrowmed.de"
                  >
                    schreib&apos; uns
                  </a>
                }
              /> */}
            </>
          )}
        {/* {productArray.map(prod => (
          <ProductTile
            title={prod.title}
            jimLimit={prod.jimLimit}
            price={prod.price}
            catchPhrase={prod.catchPhrase}
            btn={
              prod.price ? (
                <JimButton
                  variant="primary"
                  onClick={e => handleCheckoutClick(e, prod.priceID)}
                >
                  auswählen
                </JimButton>
              ) : (
                <a
                  tw="flex justify-center items-center px-2 py-1 justify-self-end rounded font-semibold tracking-wide text-white transition duration-200 select-none bg-prim-400 hover:bg-prim-600"
                  href="mailto:kontakt@morrowmed.de"
                >
                  schreib&apos; uns
                </a>
              )
            }
          />
        ))} */}
      </div>
      {/* <div tw="max-w-2xl">
        <p>
          <sup>*</sup> Die angegebene Anzahl JiMs bezieht sich auf die maximale
          Anzahl an JiMs, die Sie Ihren Pools hinzufügen können. JiMs, die Teil
          mehrerer Pools sind, werden nur einfach gezählt.
        </p>
      </div> */}
      <LoadingDialog open={checkoutSessionLoading || checkoutSessionData} />
      {checkoutSessionError && <ErrorDialog />}
    </>
  );
};
SubscriptionPlansDialog.propTypes = {
  facilityID: string.isRequired,
  subscrPlansLoading: bool.isRequired,
  subscrPlansList: shape({
    listSubscriptionPlans: arrayOf(shape()),
  }),
  subscrPlansError: shape({}),
};
SubscriptionPlansDialog.defaultProps = {
  subscrPlansList: undefined,
  subscrPlansError: undefined,
};

const SubscriptionDetailsArea = ({ facilityID }) => {
  const { userFacilitiesByID } = useContext(UserRolesContext);
  const facility = userFacilitiesByID[facilityID];
  const { current_subscription } = facility.billing;
  const {
    data: subscrPlansList,
    loading: subscrPlansLoading,
    error: subscrPlansError,
  } = useQuery(LIST_SUBSCRIPTION_PLANS, { variables: { status: "ACTIVE" } });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [
    getStripePortalSession,
    {
      data: portalSessionData,
      loading: portalSessionLoading,
      error: portalSessionError,
    },
  ] = useLazyQuery(GET_STRIPE_PORTAL_SESSION);
  // const [isGettingSessionURL, setIsGettingSessionURL] = useState(false);
  // const [isSessionURLError, setIsSessionURLError] = useState(false);

  const handlePortalClick = e => {
    e.preventDefault();
    e.stopPropagation();

    getStripePortalSession({
      variables: { facility: facilityID, return_url: window.location.href },
    }).then(({ data }) => {
      window.location.href = data.getStripePortalSession.url;
    });
  };

  return (
    <>
      <TilePrim
        title="Abos und Zahlungen"
        tw="m-0"
        btnLabel={current_subscription ? "verwalten" : undefined}
        btnHandler={current_subscription ? handlePortalClick : undefined}
      >
        {current_subscription && current_subscription.plan ? (
          <div tw="p-4">
            <table>
              <tbody>
                <tr>
                  <td>Plan</td>
                  <td tw="pl-4 font-semibold">
                    {current_subscription.plan.title}
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td tw="pl-4 font-semibold">
                    {current_subscription.status === "ACTIVE" ? (
                      <span tw="text-green-700">aktiv</span>
                    ) : (
                      <span tw="text-red-700">inaktiv</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Max. # JiMs</td>
                  <td tw="pl-4 font-semibold">
                    {current_subscription.plan.monthly_active_users}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <JimButton variant="primary" onClick={() => setDialogOpen(true)}>
              Pläne ansehen
            </JimButton>
          </div>
        )}
      </TilePrim>
      <JimDialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
        <div tw="flex justify-between">
          <div tw="w-16" />
          <div tw="flex-grow text-center text-xl text-prim-500 tracking-wider">
            Unsere Pläne
          </div>
          <div tw="flex w-16 justify-end">
            <TextButton onClick={() => setDialogOpen(false)}>abbr.</TextButton>
          </div>
        </div>
        <SubscriptionPlansDialog
          facilityID={facilityID}
          subscrPlansLoading={subscrPlansLoading}
          subscrPlansList={subscrPlansList}
          subscrPlansError={subscrPlansError}
        />
      </JimDialog>
      <LoadingDialog open={portalSessionLoading || portalSessionData} />
      {portalSessionError && <ErrorDialog />}
    </>
  );
};
SubscriptionDetailsArea.propTypes = {
  facilityID: string.isRequired,
};

export default SubscriptionDetailsArea;
