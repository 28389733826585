/* eslint-disable camelcase, react/function-component-definition, react/no-unknown-property */
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { MenuItem, TextField, Skeleton } from "@mui/material";
import { AddRounded, Print } from "@mui/icons-material";
import { number, shape, string } from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import {
  addMonths,
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  subMonths,
} from "date-fns";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import JimHelmet from "../../components/JimHelmet";
import {
  ContentWithoutUtilsBar,
  ErrorDialog,
  JimButton,
  JimDialog,
  LoadingDialog,
  TextButton,
  TilePrim,
} from "../../components/util-components";
import { PageTitleContext } from "../../utils/pagetitle-provider";
import { GET_USER_ROLES } from "../../utils/queries";
// import { UserRolesContext } from "../../utils/userroles-provider"
import withRoles from "../../utils/with-roles";
import { rightNow } from "../../utils/global-consts";
import { formatDE, minToHoursMin } from "../../utils/util-functions";
import JimLogoNew from "../../images/JimLogoNew";

const timeFilterLib = {
  lastMonth: {
    filter: {
      endsBefore: endOfMonth(subMonths(rightNow, 1)).getTime(),
      startsAfter: startOfMonth(subMonths(rightNow, 1)).getTime(),
    },
    label: formatDE(subMonths(new Date(), 1), "MMMM"),
  },
  thisMonth: {
    filter: {
      endsBefore: endOfMonth(rightNow).getTime(),
      startsAfter: startOfMonth(rightNow).getTime(),
    },
    label: formatDE(new Date(), "MMMM"),
  },
  nextMonth: {
    filter: {
      endsBefore: endOfMonth(addMonths(rightNow, 1)).getTime(),
      startsAfter: startOfMonth(addMonths(rightNow, 1)).getTime(),
    },
    label: formatDE(addMonths(new Date(), 1), "MMMM"),
  },
  thisYear: {
    filter: {
      endsBefore: endOfYear(rightNow).getTime(),
      startsAfter: startOfYear(rightNow).getTime(),
    },
    label: "Dieses Jahr",
  },
};

const ADD_WORKER = gql`
  mutation AddFacilityWorker($facility: ID!, $user: ID!) {
    addFacilityWorker(facility: $facility, user: $user) {
      id
      users {
        role
        user {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

const REMOVE_WORKER = gql`
  mutation RemoveFacilityWorker($facility: ID!, $user: ID!) {
    removeFacilityWorker(facility: $facility, user: $user) {
      id
      users {
        role
        user {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

const FIND_USER_BY_EMAIL = gql`
  query FindUserByEmail($email: String) {
    listUsers(email: $email) {
      id
    }
  }
`;

const GET_FACILITY = gql`
  query GetFacility($id: ID!, $jobsAfter: Float, $jobsBefore: Float) {
    getFacility(id: $id) {
      users(roleFilter: [WORKER]) {
        role
        user {
          id
          first_name
          last_name
          pools(facilityFilter: $id) {
            id
            title
          }
          accepted_jobs(startsAfter: $jobsAfter, endsBefore: $jobsBefore) {
            start_time
            end_time
            job {
              title
            }
            pool {
              id
              title
            }
          }
          #contracts(facility: $id) {
          #  status
          #  start
          #  end
          #  monthly_max_hours
          #  pay
          #}
        }
      }
    }
  }
`;

const ContentAreaWrapper = tw.div`flex print:hidden flex-col px-2 py-4`;

const UserListItem = ({ user, facilityID, timeFilter }) => {
  const { id, first_name, last_name, accepted_jobs } = user;
  const [
    removeFacilityWorker,
    { loading: loadingRemoveWorker, error: errorRemoveWorker },
  ] = useMutation(REMOVE_WORKER);
  const [awaitConfirmDel, setAwaitConfirmDel] = useState(false);
  const [confirmDelText, setConfirmDelText] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const handleRemove = e => {
    e.stopPropagation();

    removeFacilityWorker({
      variables: { facility: facilityID, user: id },
      refetchQueries: [
        {
          query: GET_USER_ROLES,
        },
      ],
    }).then(() => {
      setAwaitConfirmDel(false);
    });
  };

  const getTimeSumInHours = () => {
    let sum = 0;
    accepted_jobs.forEach(jobInst => {
      sum += (jobInst.end_time - jobInst.start_time) / 3600000;
    });
    return sum;
  };

  const getSumString = sum => {
    if (sum === 0) {
      return "0 Std. 0 Min.";
    }
    return `${Math.floor(sum)} Std. ${Math.round(
      (sum % Math.floor(sum)) * 60
    )} Min.`;
  };

  const getTimeForDetails = () => {
    let res;
    if (
      new Date(timeFilter.startsAfter).getYear() ===
      new Date(timeFilter.endsBefore).getYear()
    ) {
      if (
        new Date(timeFilter.startsAfter).getMonth() ===
        new Date(timeFilter.endsBefore).getMonth()
      ) {
        res = `${formatDE(timeFilter.startsAfter, "dd.")} bis ${formatDE(
          timeFilter.endsBefore,
          "dd. MMMM yyyy"
        )}`;
      } else {
        res = `${formatDE(timeFilter.startsAfter, "dd. MMM")} bis ${formatDE(
          timeFilter.endsBefore,
          "dd. MMM yyyy"
        )}`;
      }
    } else {
      res = `${formatDE(timeFilter.startsAfter, "dd. MMM yyyy")} bis ${formatDE(
        timeFilter.endsBefore,
        "dd. MMM yyyy"
      )}`;
    }

    return res;
  };

  const clickOnRow = () => {
    setShowInfo(!showInfo);
  };

  return (
    <>
      <ContentAreaWrapper onClick={clickOnRow}>
        <div tw="grid grid-cols-3">
          <div tw="flex items-center font-semibold text-lg">{`${first_name} ${last_name}`}</div>

          <div tw="flex items-center col-start-2">
            <span>{`${accepted_jobs.length} Einsätze`}</span>
            <span tw="opacity-60 ml-2">
              {accepted_jobs.length > 0 &&
                `(⌀ ${(
                  Math.round(
                    (getTimeSumInHours() / accepted_jobs.length) * 10
                  ) / 10
                )
                  .toString()
                  .replace(".", ",")} h)`}
            </span>
          </div>
          <div tw="flex items-center justify-end col-start-3">
            {getSumString(getTimeSumInHours())}
          </div>
        </div>
        {/* <Collapse in={showInfo}>
          <div tw="w-max m-4">
            <div tw="tracking-wider text-xs font-semibold mb-2">VERTRAG</div>
            <div tw="grid grid-cols-1 sm:grid-cols-2 items-start gap-x-16">
              <div tw="grid grid-cols-2 gap-x-4">
                <div>Status:</div>
                <div>
                  <CheckCircleRounded tw="text-green-500 text-xl" />
                </div>
                <div>Beginn:</div>
                <div>01.01.2022</div>
                <div>Ende (vsl.):</div>
                <div>31.12.2022</div>
              </div>
              <div tw="grid grid-cols-2 gap-x-4">
                <div>begrenzt auf:</div>
                <div>42 h/Monat</div>
                <div>Stundenlohn:</div>
                <div>9,68 €</div>
              </div>
            </div>
          </div>
        </Collapse> */}
        <JimDialog
          open={awaitConfirmDel}
          handleClose={() => setAwaitConfirmDel(false)}
          tw="items-center w-96 text-center"
        >
          <div tw="text-lg">
            <strong>{`${first_name} ${last_name}`}</strong> wirklich löschen?
          </div>
          <div tw="text-red-500">
            {`${first_name} ${last_name} wird komplett aus der Einrichtung und damit aus allen Pools entfernt. ${first_name} ${last_name} wird keine Einsätze mehr annehmen können und nicht mehr in der Verfügbarkeitssuche erscheinen.`}
          </div>
          <div tw="text-sm pt-2">
            Bitte gib zur Bestätigung den Nachnamen der Nutzerin/des Nutzers ein
          </div>
          <TextField
            variant="outlined"
            value={confirmDelText}
            onChange={e => setConfirmDelText(e.target.value)}
          />
          <div tw="flex items-center space-x-4">
            <TextButton onClick={() => setAwaitConfirmDel(false)}>
              abbr.
            </TextButton>
            <JimButton
              variant="cancel"
              onClick={handleRemove}
              disabled={confirmDelText !== last_name}
            >
              ja, entfernen
            </JimButton>
          </div>
        </JimDialog>
        <JimDialog
          open={showDetails}
          handleClose={() => setShowDetails(false)}
          title={`${first_name} ${last_name}`}
        >
          <div tw="flex flex-col">
            <div tw="flex items-center justify-between italic text-sm mb-8 print:mb-12">
              <span>{getTimeForDetails()}</span>
              <TextButton tw="print:hidden" onClick={() => window.print()}>
                <Print />
              </TextButton>
            </div>
            {accepted_jobs.length === 0 ? (
              <div tw="mb-2">{`Im gewählten Zeitraum war ${first_name} ${last_name} nicht im Einsatz.`}</div>
            ) : (
              <table>
                <tbody>
                  {accepted_jobs.map(jobInst => (
                    <tr>
                      <td tw="pr-6">
                        {formatDE(new Date(jobInst.start_time), "dd.MM.yyyy")}
                      </td>
                      <td tw="px-6 print:w-64">{jobInst.job.title}</td>
                      <td tw="px-6">
                        {`${formatDE(
                          new Date(jobInst.start_time),
                          "HH:mm"
                        )} - ${formatDE(new Date(jobInst.end_time), "HH:mm")}`}
                      </td>
                      <td tw="pl-6 py-2 font-semibold">
                        {minToHoursMin(
                          (jobInst.end_time - jobInst.start_time) / 60000
                        )}
                      </td>
                    </tr>
                  ))}
                  <tr tw="border-t border-prim-300">
                    <td />
                    <td /> <td />
                    <td tw="pl-6 py-2 font-semibold">
                      {getSumString(getTimeSumInHours())}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </JimDialog>
        <LoadingDialog open={loadingRemoveWorker} />
        {errorRemoveWorker && <ErrorDialog />}
      </ContentAreaWrapper>
      {showDetails && (
        <div tw="hidden print:flex flex-col">
          <div tw="text-xl mt-6">{`${first_name} ${last_name}`}</div>
          <div tw="flex items-center justify-between italic text-sm mb-12">
            <span>{getTimeForDetails()}</span>
          </div>
          {accepted_jobs.length === 0 ? (
            <div>{`Im gewählten Zeitraum war ${first_name} ${last_name} nicht im Einsatz.`}</div>
          ) : (
            <table>
              <tbody>
                {accepted_jobs.map(jobInst => (
                  <tr>
                    <td tw="pr-6">
                      {formatDE(new Date(jobInst.start_time), "dd.MM.yyyy")}
                    </td>
                    <td tw="px-6 print:w-64">{jobInst.job.title}</td>
                    <td tw="px-6">
                      {`${formatDE(
                        new Date(jobInst.start_time),
                        "HH:mm"
                      )} - ${formatDE(new Date(jobInst.end_time), "HH:mm")}`}
                    </td>
                    <td tw="pl-6 py-2 font-semibold">
                      {minToHoursMin(
                        (jobInst.end_time - jobInst.start_time) / 60000
                      )}
                    </td>
                  </tr>
                ))}
                <tr tw="border-t border-prim-300">
                  <td />
                  <td /> <td />
                  <td tw="pl-6 py-2 font-semibold">
                    {getSumString(getTimeSumInHours())}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <div tw="hidden print:flex opacity-70 p-4 pt-8 items-center justify-center">
            <div tw="text-sm text-gray-500">powered by</div>
            <div tw="w-8 pl-1">
              <JimLogoNew col1="#B65B8C" col2="#A73A75" col3="#991A5F" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
UserListItem.propTypes = {
  user: shape({
    id: string,
    first_name: string,
    last_name: string,
  }).isRequired,
  facilityID: string.isRequired,
  timeFilter: shape({
    endsBefore: number,
    startsAfter: number,
  }).isRequired,
};

const ManageJims = ({ facilityID }) => {
  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => setPageTitle("JiMs verwalten"), []);
  // const { userFacilitiesByID } = useContext(UserRolesContext)
  // const facility = userFacilitiesByID[facilityID]
  const [timeFilter, setTimeFilter] = useState(timeFilterLib.thisMonth.filter);
  const { data: facilityData } = useQuery(GET_FACILITY, {
    variables: {
      id: facilityID,
      jobsAfter: timeFilter.startsAfter,
      jobsBefore: timeFilter.endsBefore,
    },
  });
  const facility = facilityData ? facilityData.getFacility : { users: [] };

  const [filteredJims, setFilteredJims] = useState([]);

  const [timeSelect, setTimeSelect] = useState("thisMonth");
  const [timeFilterExt, setTimeFilterExt] = useState({
    endsBefore: dayjs(rightNow).endOf("month"),
    startsAfter: dayjs(rightNow).startOf("month"),
  });
  const [showSearchDialog, setShowSearchDialog] = useState(false);
  const [extLabel, setExtLabel] = useState("erweitert...");

  const [
    addFacilityWorker,
    { loading: addWorkerLoading, error: addWorkerError },
  ] = useMutation(ADD_WORKER);
  const [isAddingWorker, setIsAddingWorker] = useState(false);
  const [
    findUserByEmail,
    { data: userFromEmail, loading: userFromEmailLoading },
  ] = useLazyQuery(FIND_USER_BY_EMAIL);
  const [addWorkerEmail, setAddWorkerEmail] = useState("");
  const [showFindUserError, setShowFindUserError] = useState(false);
  const [showUserDuplicateError, setShowUserDuplicateError] = useState(false);

  useEffect(() => {
    if (userFromEmail && userFromEmail.listUsers.length === 1) {
      addFacilityWorker({
        variables: {
          facility: facilityID,
          user: userFromEmail.listUsers[0].id,
        },
        refetchQueries: [
          {
            query: GET_USER_ROLES,
          },
          {
            query: GET_FACILITY,
            variables: { id: facilityID },
          },
        ],
      }).then(() => setIsAddingWorker(false));
    } else if (userFromEmail && userFromEmail.listUsers.length !== 1) {
      setShowFindUserError(true);
    }
  }, [userFromEmail]);

  useEffect(() => {
    if (facilityData && facilityData.getFacility) {
      setFilteredJims(facility.users);
    }
  }, [facilityData]);

  const handleAddWorker = e => {
    e.preventDefault();
    e.stopPropagation();

    if (addWorkerEmail !== "") {
      findUserByEmail({ variables: { email: addWorkerEmail } });
    }
  };

  const handleSelectChange = e => {
    if (e.target.value === "ext") {
      setShowSearchDialog(!showSearchDialog);
    } else {
      setTimeSelect(e.target.value);
      setTimeFilter(timeFilterLib[e.target.value].filter);
    }
  };

  const handleStartChange = date => {
    if (date && date.valueOf() > timeFilterExt.endsBefore.valueOf()) {
      setTimeFilterExt({ startsAfter: date, endsBefore: date });
    } else {
      setTimeFilterExt({ ...timeFilterExt, startsAfter: date });
    }
  };

  const handleEndChange = date => {
    if (date && date.valueOf() < timeFilterExt.startsAfter.valueOf()) {
      setTimeFilterExt({ startsAfter: date, endsBefore: date });
    } else {
      setTimeFilterExt({ ...timeFilterExt, endsBefore: date });
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    setShowSearchDialog(false);
    setTimeFilter({
      endsBefore: timeFilterExt.endsBefore.valueOf(),
      startsAfter: timeFilterExt.startsAfter.valueOf(),
    });
    setTimeSelect("ext");
    if (
      timeFilterExt.startsAfter.valueOf() === timeFilterExt.endsBefore.valueOf()
    ) {
      setExtLabel(timeFilterExt.startsAfter.format("DD.MM.YY"));
    } else {
      setExtLabel(
        `${timeFilterExt.startsAfter.format(
          "DD.MM.YY"
        )} - ${timeFilterExt.endsBefore.format("DD.MM.YY")}`
      );
    }
  };

  const handleTimeSelOpen = () => {
    setExtLabel("erweitert...");
    // setTimeSelect("thisMonth")
    if (timeSelect === "ext") {
      setTimeSelect("none");
    }
  };

  const handleJimsFilter = ({ target }) => {
    const searchString = target.value.toLowerCase();
    setFilteredJims(
      facility.users.filter(userObj =>
        `${userObj.user.first_name} ${userObj.user.last_name}`
          .toLowerCase()
          .includes(searchString)
      )
    );
  };

  return (
    <>
      <JimHelmet path={`employer/facility/${facilityID}/manage-jims`} />
      <ContentWithoutUtilsBar>
        <div tw="flex print:hidden items-center">
          {isAddingWorker ? (
            <form tw="flex items-center space-x-4" onSubmit={handleAddWorker}>
              <TextField
                id="add-jim-email"
                value={addWorkerEmail}
                variant="outlined"
                label="E-Mail Adresse"
                onChange={e => setAddWorkerEmail(e.target.value)}
              />
              <JimButton
                variant="primary"
                type="submit"
                disabled={addWorkerEmail.trim() === ""}
              >
                hinzufügen
              </JimButton>
              <TextButton onClick={() => setIsAddingWorker(false)}>
                abbr.
              </TextButton>
            </form>
          ) : (
            <JimButton
              variant="primary"
              onClick={() => setIsAddingWorker(true)}
            >
              <AddRounded tw="mr-1 text-xl" />
              JiM hinzufügen
            </JimButton>
          )}
        </div>
        <TilePrim>
          <div tw="flex print:hidden justify-between space-x-2">
            <TextField
              id="find-jim"
              label="Suche"
              onChange={e => handleJimsFilter(e)}
              disabled={!(facilityData && facilityData.getFacility)}
            />
            <TextField
              id="select-time"
              value={timeSelect}
              label="Zeitraum"
              select
              variant="outlined"
              tw="w-48"
              onChange={handleSelectChange}
              SelectProps={{ onOpen: handleTimeSelOpen }}
            >
              <MenuItem key="lastMonth" value="lastMonth">
                {timeFilterLib.lastMonth.label}
              </MenuItem>
              <MenuItem key="thisMonth" value="thisMonth">
                {timeFilterLib.thisMonth.label}
              </MenuItem>
              <MenuItem key="nextMonth" value="nextMonth">
                {timeFilterLib.nextMonth.label}
              </MenuItem>
              <MenuItem key="thisYear" value="thisYear">
                {timeFilterLib.thisYear.label}
              </MenuItem>
              <MenuItem key="ext" value="ext">
                {extLabel}
              </MenuItem>
            </TextField>
          </div>
          {facilityData && facilityData.getFacility ? (
            <div tw="flex flex-col divide-y divide-prim-100 print:divide-y-0">
              {filteredJims.map(userObj => (
                <UserListItem
                  key={`jim-${userObj.user.id}`}
                  user={userObj.user}
                  facilityID={facilityID}
                  timeFilter={timeFilter}
                />
              ))}
            </div>
          ) : (
            <ContentAreaWrapper>
              <div tw="grid grid-cols-3">
                <div>
                  <Skeleton width={130} />
                </div>
                <div>
                  <Skeleton width={110} />
                </div>
                <div tw="flex justify-end">
                  <Skeleton width={80} />
                </div>
              </div>
            </ContentAreaWrapper>
          )}
        </TilePrim>
        <JimDialog
          open={showSearchDialog}
          handleClose={() => setShowSearchDialog(false)}
          tw="space-y-4 items-start"
        >
          <div tw="flex w-full justify-between">
            <div tw="text-lg font-semibold">Jobs filtern</div>
            <TextButton onClick={() => setShowSearchDialog(false)}>
              abbr.
            </TextButton>
          </div>
          <div tw="flex space-x-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="von"
                renderInput={params => <TextField {...params} />}
                value={timeFilterExt.startsAfter}
                onChange={date => handleStartChange(date)}
                inputFormat="ddd, DD. MMM YYYY"
              />
              <MobileDatePicker
                label="bis"
                renderInput={params => <TextField {...params} />}
                value={timeFilterExt.endsBefore}
                onChange={date => handleEndChange(date)}
                inputFormat="ddd, DD. MMM YYYY"
              />
            </LocalizationProvider>
          </div>
          <JimButton variant="primary" onClick={handleFilter}>
            anwenden
          </JimButton>
        </JimDialog>
        <JimDialog
          open={showFindUserError}
          handleClose={() => setShowFindUserError(false)}
        >
          <div>
            Leider konnten wir anhand deiner Eingabe keinen Nutzer finden. Bitte
            überprüfe die eingegebene Email-Adresse.
          </div>
        </JimDialog>
        <LoadingDialog open={addWorkerLoading || userFromEmailLoading} />
        {addWorkerError &&
          (addWorkerError.message === "User is already worker in Facility!" ? (
            <JimDialog
              open={showUserDuplicateError}
              handleClose={() => setShowUserDuplicateError(false)}
            >
              <div>
                Der Nutzer ist bereits als JiM in der Einrichtung hinterlegt.
              </div>
            </JimDialog>
          ) : (
            <ErrorDialog />
          ))}
      </ContentWithoutUtilsBar>
    </>
  );
};
ManageJims.propTypes = {
  facilityID: string.isRequired,
};

export default withRoles(ManageJims, "EMPLOYER");
