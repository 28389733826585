/* eslint-disable camelcase, react/function-component-definition */
import { gql, useMutation } from "@apollo/client";
import { WarningTwoTone } from "@mui/icons-material";
import { Skeleton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import tw, { styled } from "twin.macro";
import { arrayOf, number, shape, string } from "prop-types";

import { arrayCreator, formatDE } from "../../../utils/util-functions";
import { JimButton, TextButton } from "../../util-components";

const EDIT_FACILITY = gql`
  mutation EditFacility($id: ID!, $name: String, $address: AddressInput) {
    editFacility(id: $id, name: $name, address: $address) {
      id
      name
      address {
        city
        zip
        number
        street
      }
    }
  }
`;

const TextFieldWithWidth = styled(TextField)(({ width }) => [
  `width: ${width}%;`,
]);
const ContentAreaWrapper = tw.div`flex flex-col p-4 space-y-2`;

const labelLib = {
  first_name: "Vorname",
  last_name: "Nachname",
  zip: "PLZ",
  city: "Stadt",
  street: "Straße",
  number: "Nr.",
  // phone: "primär",
  // phone2: "sekundär",
};

const ContentArea = ({ title, items, widthArr, facilityData }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formVars, setFormVars] = useState(null);
  const [editFacility] = useMutation(EDIT_FACILITY);

  const getOriginalFormVars = () => {
    const varObj = {};
    if (title === "Adresse") {
      items.forEach(item => {
        varObj[item] = facilityData.address[item];
      });
    } else {
      items.forEach(item => {
        varObj[item] = facilityData[item];
      });
    }
    return varObj;
  };

  useEffect(() => {
    if (facilityData && facilityData.id) {
      setFormVars(getOriginalFormVars());
    }
  }, [facilityData]);

  const handleSave = e => {
    e.preventDefault();

    const mutationVars = { id: facilityData.id };
    if (title === "Adresse") {
      mutationVars.address = {};
      Object.keys(formVars).forEach(item => {
        mutationVars.address[item] = formVars[item];
      });
    } else {
      Object.keys(formVars).forEach(item => {
        mutationVars[item] = formVars[item];
      });
    }

    editFacility({
      variables: mutationVars,
      optimisticResponse: {
        __typename: "Mutation",
        editFacility: {
          ...mutationVars,
          status: "PENDING",
        },
      },
    });
    setIsEditing(false);
  };

  const handleEditToggle = () => {
    if (isEditing) {
      setIsEditing(false);
      setFormVars(getOriginalFormVars());
    } else {
      setIsEditing(true);
    }
  };

  const getWarning = () => {
    let isEmpty = false;
    Object.keys(formVars).forEach(key => {
      if (formVars[key] === "" || !formVars[key]) {
        isEmpty = true;
      }
    });
    if (isEmpty) {
      return (
        <div tw="text-base text-yellow-500 ml-2">
          <WarningTwoTone fontSize="inherit" />
        </div>
      );
    }
    return null;
  };

  return (
    <ContentAreaWrapper>
      {formVars ? (
        <>
          <div tw="flex items-center justify-between">
            <div tw="flex items-center">
              {title}
              {getWarning()}
            </div>
            <TextButton onClick={handleEditToggle}>
              {isEditing ? "abbrechen" : "bearbeiten"}
            </TextButton>
          </div>
          {isEditing ? (
            <>
              <div tw="flex space-x-4 pt-2">
                {arrayCreator(0, 1, 1).map(
                  ind =>
                    items[ind] && (
                      <TextFieldWithWidth
                        key={`text-field-${ind}`}
                        width={widthArr[ind]}
                        id={`${items[ind]}-input`}
                        value={formVars[items[ind]]}
                        label={labelLib[items[ind]]}
                        variant="outlined"
                        onChange={e =>
                          setFormVars({
                            ...formVars,
                            [items[ind]]: e.target.value,
                          })
                        }
                      />
                    )
                )}
              </div>
              {items[2] && (
                <div tw="flex space-x-4 pt-2">
                  {arrayCreator(2, 3, 1).map(ind => (
                    <TextFieldWithWidth
                      key={`text-field-${ind}`}
                      width={widthArr[ind]}
                      id="last-name-input"
                      value={formVars[items[ind]]}
                      label={labelLib[items[ind]]}
                      variant="outlined"
                      onChange={e =>
                        setFormVars({
                          ...formVars,
                          [items[ind]]: e.target.value,
                        })
                      }
                    />
                  ))}
                </div>
              )}
              <div tw="flex justify-start pt-2">
                <JimButton variant="primary" onClick={handleSave}>
                  speichern
                </JimButton>
              </div>
            </>
          ) : (
            <div tw="font-semibold">
              {items.length > 2 ? (
                `${formVars[items[0]] ? formVars[items[0]] : ""} ${
                  formVars[items[1]] ? formVars[items[1]] : ""
                }${formVars[items[2]] || formVars[items[3]] ? ", " : ""}${
                  formVars[items[2]] ? formVars[items[2]] : ""
                } ${formVars[items[3]] ? formVars[items[3]] : ""}`
              ) : (
                <>
                  {items[0] === "date_of_birth"
                    ? formatDE(formVars.date_of_birth, "dd.MM.yyyy")
                    : items.map(item => formVars[item] && `${formVars[item]} `)}
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <Skeleton animation="wave" width={40} />
          <div tw="flex space-x-2">
            <Skeleton animation="wave" width={80} />
            {items[1] && <Skeleton animation="wave" width={60} />}
          </div>
        </>
      )}
    </ContentAreaWrapper>
  );
};
ContentArea.propTypes = {
  title: string.isRequired,
  items: arrayOf(string).isRequired,
  widthArr: arrayOf(number).isRequired,
  facilityData: shape({
    getFacility: shape({
      id: string,
      name: string,
    }),
  }),
};
ContentArea.defaultProps = {
  facilityData: shape({}),
};

export default ContentArea;
