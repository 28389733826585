/* eslint-disable react/function-component-definition */
import { differenceInMinutes } from "date-fns";
import { number, shape } from "prop-types";
import React from "react";
import tw from "twin.macro"; // eslint-disable-line no-unused-vars
import { minToHoursMin } from "../../../utils/util-functions";

const DurationString = ({ formVars }) => {
  const duration = differenceInMinutes(formVars.end_time, formVars.start_time);
  if (duration / 60 > 16) {
    return (
      <div tw="flex">
        <div>Arbeitszeit:</div>
        <div tw="ml-8 font-semibold tracking-wide text-red-500">
          {minToHoursMin(duration)}
        </div>
      </div>
    );
  }
  if (duration < 30) {
    return (
      <div tw="font-semibold tracking-wide text-red-500">
        Die Arbeitszeit muss mind. 30 Min. betragen
      </div>
    );
  }
  return (
    <div tw="flex">
      <div>Arbeitszeit:</div>
      <div tw="ml-8 font-semibold tracking-wide">{minToHoursMin(duration)}</div>
    </div>
  );
};
DurationString.propTypes = {
  formVars: shape({
    start_time: number,
    end_time: number,
  }).isRequired,
};

export default DurationString;
