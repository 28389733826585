/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/function-component-definition */
import { gql, useMutation, useQuery } from "@apollo/client";
import { TextField } from "@mui/material";
import { GroupTwoTone, InfoTwoTone } from "@mui/icons-material";
import { navigate } from "gatsby";
import { pickBy } from "lodash";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro"; // eslint-disable-line no-unused-vars

import {
  ContentWithoutUtilsBar,
  ErrorDialog,
  JimButton,
  JimDialog,
  LoadingDialog,
  LoadingTile,
  TextButton,
  TilePrim,
  UtilsBarWrapper,
} from "../../components/util-components";
import { PageTitleContext } from "../../utils/pagetitle-provider";
import { UserRolesContext } from "../../utils/userroles-provider";
import { arrayCreator } from "../../utils/util-functions";
import withRoles from "../../utils/with-roles";
import JimHelmet from "../../components/JimHelmet";

const LIST_POOLS_BY_FACILITY = gql`
  query ListPools($facility: [ID]) {
    listPools(facility: $facility) {
      id
      title
      description
      workers {
        id
        first_name
        last_name
        phone
        email
      }
    }
  }
`;

const CREATE_POOL = gql`
  mutation CreatePool($pool: PoolInput!) {
    createPool(pool: $pool) {
      id
      title
      description
    }
  }
`;

const EDIT_POOL = gql`
  mutation EditPool($id: ID!, $title: String, $description: String) {
    editPool(id: $id, title: $title, description: $description) {
      id
      title
      description
    }
  }
`;

const DELETE_POOL = gql`
  mutation DeletePool($id: ID!) {
    deletePool(id: $id) {
      id
    }
  }
`;

const PoolForm = ({
  handleSubmit,
  handleClose,
  formVars,
  setFormVars,
  editing,
}) => (
  <form
    id="create-pool-form"
    onSubmit={handleSubmit}
    tw="flex flex-col space-y-4"
  >
    <TextField
      id="pool-title"
      label="Name"
      error={formVars.title.length > 0 && !formVars.title.trim()}
      value={formVars.title}
      variant="outlined"
      onChange={e => {
        setFormVars({ ...formVars, title: e.target.value });
      }}
      required
    />
    <TextField
      id="pool-descr"
      label="Beschreibung"
      multiline
      rows={5}
      value={formVars.description}
      variant="outlined"
      onChange={e => {
        setFormVars({ ...formVars, description: e.target.value });
      }}
    />
    <div tw="flex items-center justify-end space-x-4">
      <TextButton onClick={handleClose}>abbr.</TextButton>
      <JimButton
        variant="primary"
        type="submit"
        disabled={!formVars.title.trim()}
      >
        {editing ? "speichern" : "erstellen"}
      </JimButton>
    </div>
  </form>
);
PoolForm.propTypes = {
  handleSubmit: func.isRequired,
  handleClose: func.isRequired,
  formVars: shape({
    title: string,
    description: string,
  }).isRequired,
  setFormVars: func.isRequired,
  editing: bool,
};
PoolForm.defaultProps = {
  editing: false,
};

const PoolTile = ({ pool, facilityID }) => {
  const [deletePool, { loading, error }] = useMutation(DELETE_POOL);
  const [editPool, { loading: editLoading, error: editError }] =
    useMutation(EDIT_POOL);
  const [awaitConfirmDel, setAwaitConfirmDel] = useState(false);
  const [confirmDelText, setConfirmDelText] = useState("");
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [formVars, setFormVars] = useState({
    title: pool.title,
    description: pool.description,
  });

  const handleClickOnTile = e => {
    e.stopPropagation();
    // navigate("/pools/jims", { state: { poolID: pool.id } })
    navigate(`/employer/${facilityID}/facility-settings/jims/${pool.id}`);
  };

  const handleClickEdit = e => {
    e.stopPropagation();
    setShowEditDialog(true);
  };

  const handleSaveChanges = e => {
    e.preventDefault();
    setShowEditDialog(false);
    editPool({ variables: { id: pool.id, ...formVars } });
  };

  const handleDeletePool = e => {
    e.stopPropagation();
    deletePool({
      variables: { id: pool.id },
      refetchQueries: [
        {
          query: LIST_POOLS_BY_FACILITY,
          variables: { facility: [facilityID] },
        },
      ],
    });
  };

  const handleClickOnDel = e => {
    e.stopPropagation();
    setAwaitConfirmDel(true);
  };

  const handleCancel = () => {
    setShowEditDialog(false);
    setFormVars({
      title: pool.title,
      description: pool.description,
    });
  };

  return (
    <>
      <TilePrim clickable onClick={handleClickOnTile}>
        <div tw="flex items-center justify-between">
          <div tw="text-lg text-prim-500 tracking-wider">{pool.title}</div>
          <div tw="flex space-x-4">
            <TextButton onClick={handleClickEdit}>bearbeiten</TextButton>
            <TextButton onClick={handleClickOnDel}>löschen</TextButton>
          </div>
        </div>
        {pool.description && (
          <div tw="flex space-x-2">
            <InfoTwoTone />
            <div>{pool.description}</div>
          </div>
        )}
        <div tw="flex space-x-2">
          <GroupTwoTone />
          <div tw="flex flex-col space-y-2">
            {pool.workers[0] ? (
              <>
                {arrayCreator(0, 1, 1).map(
                  val =>
                    pool.workers[val] && (
                      <div>{`${pool.workers[val].first_name} ${pool.workers[val].last_name}`}</div>
                    )
                )}
                {pool.workers[2] && <div>...</div>}
              </>
            ) : (
              <div tw="italic">aktuell leer</div>
            )}
          </div>
        </div>
      </TilePrim>
      <JimDialog
        open={awaitConfirmDel}
        handleClose={() => setAwaitConfirmDel(false)}
        tw="items-center w-96 text-center"
      >
        <div tw="text-lg">
          Pool <strong>{pool.title}</strong> wirklich löschen?
        </div>
        <div>
          Wenn du einen Pool löscht, kannst du die für ihn bereits erstellten
          Jobs noch sehen.
        </div>
        <div tw="text-red-500">
          JiMs, die nach Einsätzen suchen, können diese Jobs jedoch nicht mehr
          finden.
        </div>
        <div>
          Falls du diesen Pool gerade erst erstellt hast und ihn noch nicht für
          die Erstellung von Einsätzen oder anderweitig verwendet hast, hat das
          Löschen des Pools keine weiteren Konsequenzen.
        </div>
        <div tw="text-sm pt-2">
          Bitte gib zur Bestätigung den Namen des Pools ein
        </div>
        <TextField
          variant="outlined"
          value={confirmDelText}
          onChange={e => setConfirmDelText(e.target.value)}
        />
        <div tw="flex items-center space-x-4">
          <TextButton onClick={() => setAwaitConfirmDel(false)}>
            abbr.
          </TextButton>
          <JimButton
            variant="cancel"
            onClick={handleDeletePool}
            disabled={confirmDelText !== pool.title}
          >
            ja, löschen
          </JimButton>
        </div>
      </JimDialog>
      <JimDialog open={showEditDialog} handleClose={handleCancel} tw="w-96">
        <PoolForm
          handleSubmit={handleSaveChanges}
          handleClose={handleCancel}
          formVars={formVars}
          setFormVars={setFormVars}
          editing
        />
      </JimDialog>
      <LoadingDialog open={loading || editLoading} />
      {(error || editError) && <ErrorDialog />}
    </>
  );
};
PoolTile.propTypes = {
  pool: shape({
    id: string,
    title: string,
    description: string,
    workers: arrayOf(
      shape({
        id: string,
        first_name: string,
        last_name: string,
        phone: string,
        email: string,
      })
    ),
  }).isRequired,
  facilityID: string.isRequired,
};

const FacilityPools = ({ facilityID }) => {
  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => setPageTitle("Pools verwalten"), []);
  const { userID } = useContext(UserRolesContext);
  const { data, loading } = useQuery(LIST_POOLS_BY_FACILITY, {
    variables: { facility: [facilityID] },
  });
  const [createPool, { loading: createPoolLoading }] = useMutation(
    CREATE_POOL,
    {
      refetchQueries: [
        {
          query: LIST_POOLS_BY_FACILITY,
          variables: { facility: [facilityID] },
        },
      ],
    }
  );
  const [showAddPoolDialog, setShowAddPoolDialog] = useState(false);
  const [formVars, setFormVars] = useState({
    title: "",
    description: "",
    facility: facilityID,
  });

  const handleCreatePool = e => {
    e.preventDefault();
    setShowAddPoolDialog(false);
    createPool({
      variables: {
        pool: { ...pickBy(formVars), admins: [userID] },
      },
      refetchQueries: [
        {
          query: LIST_POOLS_BY_FACILITY,
          variables: { facility: [facilityID] },
        },
      ],
    });
  };

  return (
    <>
      <JimHelmet path={`employer/${facilityID}/facility-settings/pools`} />
      <ContentWithoutUtilsBar>
        <UtilsBarWrapper>
          {data && (
            <JimButton
              variant="primary"
              onClick={() => setShowAddPoolDialog(true)}
            >
              Pool erstellen
            </JimButton>
          )}
        </UtilsBarWrapper>
        {data && !data.listPools[0] && (
          <TilePrim tw="items-center">
            <div>
              Aktuell scheint für diese Einrichtung kein Pool zu existieren.
            </div>
            <div tw="flex items-center">
              Wähle eine andere Einrichtung oder{" "}
              <TextButton onClick={() => setShowAddPoolDialog(true)}>
                erstelle einen neuen Pool
              </TextButton>
            </div>
          </TilePrim>
        )}
        {data &&
          data.listPools[0] &&
          data.listPools.map(pool => (
            <PoolTile pool={pool} facilityID={facilityID} />
          ))}
        {loading && <LoadingTile />}
        <JimDialog
          open={showAddPoolDialog}
          handleClose={() => setShowAddPoolDialog(false)}
          tw="w-96"
          title="Pool erstellen"
        >
          <PoolForm
            handleSubmit={handleCreatePool}
            handleClose={() => setShowAddPoolDialog(false)}
            formVars={formVars}
            setFormVars={setFormVars}
            editing
          />
        </JimDialog>
        <LoadingDialog open={createPoolLoading} />
      </ContentWithoutUtilsBar>
    </>
  );
};
FacilityPools.propTypes = {
  facilityID: string.isRequired,
};

export default withRoles(FacilityPools, "EMPLOYER");
