/* eslint-disable react/function-component-definition */
import { navigate } from "gatsby";
import { bool, func, string } from "prop-types";
import React from "react";
import tw from "twin.macro"; // eslint-disable-line no-unused-vars

import { JimButton, JimDialog } from "../../util-components";

const LimitReachedDialog = ({ open, handleClose, facilityID }) => {
  const handleBtnClick = () => {
    navigate(`/employer/${facilityID}/facility-settings/edit`);
  };

  return (
    <JimDialog
      title="Limit erreicht"
      open={open}
      closable
      handleClose={handleClose}
    >
      <div tw="max-w-sm pb-4">
        Du hast bereits die maximale Anzahl aktiver JiMs erreicht. Um weitere
        JiMs zu deinen Pools hinzuzufügen, musst du in einen größeren Plan
        wechseln.
      </div>
      <JimButton variant="primary" onClick={handleBtnClick}>
        Abo verwalten
      </JimButton>
    </JimDialog>
  );
};
LimitReachedDialog.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  facilityID: string.isRequired,
};

export default LimitReachedDialog;
