/* eslint-disable react/function-component-definition, react/no-unknown-property */
import { MobileDatePicker, TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import { TextField } from "@mui/material";
// import { addDays, differenceInCalendarDays } from "date-fns";
import { bool, func, number, shape, string } from "prop-types";
import React, { useState } from "react";
import tw from "twin.macro"; // eslint-disable-line no-unused-vars

import { JimButton, JimDialog, TextButton } from "./util-components";

const JobFinishDialog = ({ open, handleClose, jobInst, handleConfirm }) => {
  const [actualTimes, setActualTimes] = useState({
    start: dayjs(
      jobInst.actual_start_time ? jobInst.actual_start_time : jobInst.start_time
    ),
    end: dayjs(
      jobInst.actual_end_time ? jobInst.actual_end_time : jobInst.end_time
    ),
  });
  const [actualDates, setActualDates] = useState({
    start: dayjs(
      jobInst.actual_start_time ? jobInst.actual_start_time : jobInst.start_time
    ),
    end: dayjs(
      jobInst.actual_end_time ? jobInst.actual_end_time : jobInst.end_time
    ),
  });

  const [waitForConfirm, setWaitForConfirm] = useState(false);

  const handleDateChange = (date, key) => {
    setActualDates({ ...actualDates, [key]: date });
    if (date && date.valueOf()) {
      const timeNew = dayjs(date.valueOf())
        .hour(actualTimes[key].hour())
        .minute(actualTimes[key].minute());
      setActualTimes({ ...actualTimes, [key]: timeNew });
    }
  };

  const noTimeErr =
    actualTimes.start &&
    actualTimes.end &&
    actualTimes.start.valueOf() &&
    !Number.isNaN(actualTimes.start.valueOf()) &&
    actualTimes.end.valueOf() &&
    !Number.isNaN(actualTimes.end.valueOf()) &&
    actualDates.start &&
    actualDates.end &&
    actualDates.start.valueOf() &&
    !Number.isNaN(actualDates.start.valueOf()) &&
    actualDates.end.valueOf() &&
    !Number.isNaN(actualDates.end.valueOf()) &&
    actualTimes.end.valueOf() > actualTimes.start.valueOf();

  return (
    <JimDialog
      open={open}
      handleClose={handleClose}
      title="Job beenden"
      closable={!waitForConfirm}
      tw="space-y-4"
    >
      {waitForConfirm ? (
        <div tw="flex flex-col items-center space-y-4">
          <div tw="flex flex-col items-center">
            <div>Bitte überprüfe deine Eingaben:</div>
            <div>
              Start:{" "}
              <strong>
                {actualTimes.start.format("DD.MMM YYYY HH:mm")} Uhr
              </strong>
            </div>
            <div>
              Ende:{" "}
              <strong>{actualTimes.end.format("DD.MMM YYYY HH:mm")} Uhr</strong>
            </div>
          </div>
          <div tw="flex items-center space-x-4">
            <TextButton onClick={() => setWaitForConfirm(false)}>
              korrigieren
            </TextButton>
            <JimButton
              variant="confirm"
              onClick={e => handleConfirm(e, actualTimes)}
            >
              bestätigen
            </JimButton>
          </div>
        </div>
      ) : (
        <>
          <div>Bitte trage die tatsächliche Dauer des Einsatzes ein:</div>
          <div tw="grid grid-cols-2 gap-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                closeOnSelect
                tw="max-w-2xs"
                label="Beginn"
                renderInput={params => <TextField {...params} />}
                value={actualDates.start}
                onChange={time => handleDateChange(time, "start")}
                inputFormat="DD. MMM YYYY"
              />
              <TimePicker
                ampm={false}
                tw="max-w-2xs"
                renderInput={params => <TextField {...params} />}
                value={actualTimes.start}
                onChange={time =>
                  setActualTimes({ ...actualTimes, start: time })
                }
                inputFormat="HH:mm"
              />
              <MobileDatePicker
                closeOnSelect
                tw="max-w-2xs"
                label="Ende"
                renderInput={params => <TextField {...params} />}
                inputVariant="outlined"
                value={actualDates.end}
                onChange={time => handleDateChange(time, "end")}
                inputFormat="DD. MMM YYYY"
              />
              <TimePicker
                ampm={false}
                tw="max-w-2xs"
                renderInput={params => <TextField {...params} />}
                value={actualTimes.end}
                onChange={time => setActualTimes({ ...actualTimes, end: time })}
                inputFormat="HH:mm"
              />
            </LocalizationProvider>
          </div>
          <JimButton
            variant="confirm"
            onClick={() => setWaitForConfirm(true)}
            disabled={!noTimeErr}
            tw="self-start"
          >
            beenden
          </JimButton>
        </>
      )}
    </JimDialog>
  );
};
JobFinishDialog.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  jobInst: shape({
    start_time: number,
    end_time: number,
    actual_start_time: number,
    actual_end_time: number,
    length: number,
    job: shape({
      title: string,
    }),
  }).isRequired,
  handleConfirm: func.isRequired,
};

export default JobFinishDialog;
