/* eslint-disable camelcase, react/function-component-definition */
import React, { useContext, useEffect, useState } from "react";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import tw, { css, styled, theme } from "twin.macro"; // eslint-disable-line no-unused-vars
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Skeleton, Tooltip } from "@mui/material";
import {
  CheckCircleOutlineRounded,
  EmailRounded,
  PhoneRounded,
} from "@mui/icons-material";
import { areIntervalsOverlapping } from "date-fns";
import { pickBy } from "lodash";

import { ScreenSizeContext } from "../utils/screensize-provider";
import {
  ErrorDialog,
  JimDialog,
  LoadingDialog,
  TextButton,
  TilePrim,
} from "./util-components";
import { payPerHour } from "../utils/global-consts";
import CreateJobForm from "./page-components/create-job/CreateJobForm";
import { LIST_JOBS_QUERY } from "../utils/queries";
import { formatDE } from "../utils/util-functions";
import { UserRolesContext } from "../utils/userroles-provider";

export const LIST_AVAILABILITIES_QUERY = gql`
  query ListAvailabilities(
    $startsBefore: Float
    $startsAfter: Float
    $endsBefore: Float
    $endsAfter: Float
    $pool: [ID]
  ) {
    listAvailabilities(
      startsBefore: $startsBefore
      startsAfter: $startsAfter
      endsBefore: $endsBefore
      endsAfter: $endsAfter
      pool: $pool
    ) {
      id
      start_time
      end_time
      worker {
        id
        first_name
        last_name
        email
        phone
        accepted_jobs(
          startsBefore: $startsBefore
          startsAfter: $startsAfter
          endsBefore: $endsBefore
          endsAfter: $endsAfter
        ) {
          id
          start_time
          end_time
        }
      }
    }
  }
`;

const CREATE_JOBINSTANCES_MUTATION = gql`
  mutation CreateJobInstances($jobInstances: [JobInstanceInput]!) {
    createJobInstances(jobInstances: $jobInstances) {
      id
      job {
        id
        title
      }
      status
      department {
        id
        name
      }
      start_time
      end_time
      length
      pay
      worker {
        id
        first_name
        last_name
      }
    }
  }
`;

const BarWrapper = styled.div(({ left, width, jobAlreadyCreated, barType }) => [
  tw`absolute opacity-80 h-3/5 rounded-lg shadow-md transition duration-200`,
  `margin-left: ${left}%;
  width: ${width}%;`,
  barType === "avail" && tw`bg-green-400`,
  barType === "job" && tw`bg-yellow-400`,
  barType === "search" && tw` h-1/3 opacity-70`,
  barType === "search" &&
    `background: repeating-linear-gradient(
    135deg, ${theme`colors.yellow.300`} 0 5px, ${theme`colors.yellow.500`} 5px 10px);`,
  !jobAlreadyCreated && tw`hover:(shadow-lg opacity-100)`,
]);

const WorkerArea = styled.div(({ isSel }) => [
  tw`flex flex-col w-full sm:w-1/3 lg:w-1/5 p-4 transition duration-200`,
  isSel ? tw`bg-prim-50` : tw`bg-white`,
]);

const BarArea = styled.div(() => [
  tw`flex items-center relative transition duration-200 h-1/3`,
]);

const JobBar = ({ jobInst }) => {
  const { start_time, end_time } = jobInst;
  const getMarginLeft = () => {
    const startDateObj = new Date(start_time);
    return (
      ((startDateObj.getHours() + startDateObj.getMinutes() / 60) / 24) * 100
    );
  };

  const getWidth = () => {
    const endDateObj = new Date(end_time);
    return (
      ((endDateObj.getHours() + endDateObj.getMinutes() / 60) / 24) * 100 -
      getMarginLeft()
    );
  };
  return (
    <Tooltip
      title={`${formatDE(new Date(start_time), "HH:mm")} - ${formatDE(
        new Date(end_time),
        "HH:mm"
      )}`}
    >
      <BarWrapper barType="job" left={getMarginLeft()} width={getWidth()} />
    </Tooltip>
  );
};
JobBar.propTypes = {
  jobInst: shape({
    start_time: number.isRequired,
    end_time: number.isRequired,
  }).isRequired,
};

const SearchBar = ({ timeVars }) => {
  const getMarginLeft = () => {
    const startDateObj = new Date(timeVars.startsBefore);
    return (
      ((startDateObj.getHours() + startDateObj.getMinutes() / 60) / 24) * 100
    );
  };

  const getWidth = () => {
    const endDateObj = new Date(timeVars.endsAfter);
    return (
      ((endDateObj.getHours() + endDateObj.getMinutes() / 60) / 24) * 100 -
      getMarginLeft()
    );
  };

  return (
    <BarWrapper barType="search" left={getMarginLeft()} width={getWidth()} />
  );
};
SearchBar.propTypes = {
  timeVars: shape({
    startsBefore: number,
    endsAfter: number,
  }).isRequired,
};

const AvailBar = ({ avail, handleClickOnBar, jobAlreadyCreated }) => {
  const { start_time, end_time } = avail;
  const getMarginLeft = () => {
    const startDateObj = new Date(start_time);
    return (
      ((startDateObj.getHours() + startDateObj.getMinutes() / 60) / 24) * 100
    );
  };

  const getWidth = () => {
    const endDateObj = new Date(end_time);
    return (
      ((endDateObj.getHours() + endDateObj.getMinutes() / 60) / 24) * 100 -
      getMarginLeft(avail)
    );
  };

  return (
    <Tooltip
      title={`${formatDE(new Date(start_time), "HH:mm")} - ${formatDE(
        new Date(end_time),
        "HH:mm"
      )}`}
    >
      <BarWrapper
        barType="avail"
        left={getMarginLeft(avail)}
        width={getWidth(avail)}
        onClick={
          jobAlreadyCreated ? undefined : e => handleClickOnBar(e, avail)
        }
        jobAlreadyCreated={jobAlreadyCreated}
      />
    </Tooltip>
  );
};
AvailBar.propTypes = {
  avail: shape({
    start_time: number.isRequired,
    end_time: number.isRequired,
    worker: shape({
      first_name: string.isRequired,
      last_name: string.isRequired,
      phone: string.isRequired,
      email: string.isRequired,
    }),
  }).isRequired,
  handleClickOnBar: func.isRequired,
  jobAlreadyCreated: bool,
};
AvailBar.defaultProps = {
  jobAlreadyCreated: false,
};

const AvailDetails = ({
  availsArr,
  jobAlreadyCreated,
  jimSelected,
  setJimSelected,
  refetchVars,
  facilityID,
}) => {
  const { worker } = availsArr[0];
  const screen = useContext(ScreenSizeContext);
  const { userFacilitiesByID } = useContext(UserRolesContext);
  const [
    getFacilityJobs,
    { data: jobListData, loading: jobListLoading, error: jobListError },
  ] = useLazyQuery(LIST_JOBS_QUERY);
  const [
    createJobInstances,
    { loading: createJobLoading, error: createJobError },
  ] = useMutation(CREATE_JOBINSTANCES_MUTATION);

  const [minMaxTime, setMinMaxTime] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showCreateJobDialog, setShowCreateJobDialog] = useState(false);
  const [showOverlapError, setShowOverlapError] = useState(false);
  /* eslint-disable-next-line */
  const deptList = userFacilitiesByID[facilityID].departments;
  const [formVars, setFormVars] = useState({});

  useEffect(() => {
    if (!jobAlreadyCreated) {
      getFacilityJobs();
    }
  }, []);

  const getTimeTag = avail => `${formatDE(
    new Date(avail.start_time),
    "HH:mm"
  )} -
        ${formatDE(new Date(avail.end_time), "HH:mm")}`;

  const isOverlap = () => {
    let overlapping = false;
    worker.accepted_jobs.forEach(jobInst => {
      if (
        areIntervalsOverlapping(
          {
            start: new Date(formVars.start_time),
            end: new Date(formVars.end_time),
          },
          {
            start: new Date(jobInst.start_time),
            end: new Date(jobInst.end_time),
          }
        )
      ) {
        overlapping = true;
      }
    });
    return overlapping;
  };

  const handleClickOnTile = e => {
    e.preventDefault();
    e.stopPropagation();
    if (jobAlreadyCreated) {
      if (isOverlap()) {
        setShowOverlapError(true);
      } else if (jimSelected === worker.id) {
        setJimSelected(null);
      } else {
        setJimSelected(worker.id);
      }
    } else {
      setShowDetails(true);
    }
  };

  const handleClickOnBar = (e, avail) => {
    e.stopPropagation();
    e.preventDefault();
    setFormVars({
      dept: deptList[0].id,
      job_id: null,
      title: null,
      description: "Beschreibung...",
      start_time: avail.start_time,
      end_time: avail.end_time,
      num_req_workers: 1,
    });
    setMinMaxTime({ min: avail.start_time, max: avail.end_time });
    setShowCreateJobDialog(true);
  };

  const handleSubmit = jobID => {
    if (isOverlap()) {
      setShowOverlapError(true);
    } else {
      const pay =
        ((formVars.end_time - formVars.start_time) / 3600000) * payPerHour;

      const newJobObj = pickBy({
        department: formVars.dept,
        start_time: formVars.start_time,
        end_time: formVars.end_time,
        job: jobID,
        pay,
        length: (formVars.end_time - formVars.start_time) / 3600000,
        description: formVars.description,
        worker: worker.id,
        instant_book: true,
      });
      const newJobsArr = [];
      for (let i = 0; i < formVars.num_req_workers; i += 1) {
        newJobsArr.push(newJobObj);
      }

      createJobInstances({
        variables: { jobInstances: newJobsArr },
        refetchQueries: [
          {
            query: LIST_AVAILABILITIES_QUERY,
            variables: refetchVars,
          },
        ],
      }).then(() => setShowCreateJobDialog(false));
    }
  };

  return (
    <>
      <TilePrim
        tw="flex-row divide-y-0 space-y-0 cursor-pointer hover:shadow-lg transition duration-200 sm:col-span-3 lg:col-span-5 p-0 bg-transparent"
        onClick={handleClickOnTile}
      >
        <WorkerArea isSel={worker.id === jimSelected}>
          <div tw="flex min-w-max items-center justify-between sm:(justify-start space-x-8)">
            <div tw="text-lg font-semibold">{`${worker.first_name[0]}. ${worker.last_name}`}</div>
          </div>
          <div tw="flex">
            <Tooltip title={worker.phone}>
              <TextButton>
                <a
                  tw="flex items-center"
                  href={`tel:${worker.phone}`}
                  onClick={e => e.stopPropagation()}
                >
                  <PhoneRounded tw="text-xl mr-2" />
                </a>
              </TextButton>
            </Tooltip>
            <Tooltip title={worker.email}>
              <TextButton>
                <a
                  tw="flex items-center"
                  href={`mailto:${worker.email}`}
                  onClick={e => e.stopPropagation()}
                >
                  <EmailRounded tw="text-xl mr-2" />
                </a>
              </TextButton>
            </Tooltip>
          </div>
        </WorkerArea>
        {screen.smUp && (
          <div
            css={[
              tw`flex flex-grow bg-white bg-opacity-50`,
              worker.id === jimSelected && tw`bg-prim-50`,
            ]}
          >
            <div tw="flex flex-col flex-grow justify-center">
              <BarArea>
                {screen.mdUp && (
                  <div tw="absolute text-sm text-green-400 italic ml-2">
                    verfügbar
                  </div>
                )}
                {availsArr.map(avail => (
                  <AvailBar
                    key={`avail-bar-${avail.id}`}
                    avail={avail}
                    handleClickOnBar={handleClickOnBar}
                    jobAlreadyCreated={jobAlreadyCreated}
                    twoBars={worker.accepted_jobs[0]}
                  />
                ))}
              </BarArea>
              {worker.accepted_jobs[0] && (
                <BarArea>
                  {screen.mdUp && (
                    <div tw="absolute text-sm text-yellow-400 italic ml-2">
                      im Einsatz
                    </div>
                  )}
                  {worker.accepted_jobs.map(jobInst => (
                    <JobBar key={`job-bar-${jobInst.id}`} jobInst={jobInst} />
                  ))}
                </BarArea>
              )}
              {jobAlreadyCreated && (
                <BarArea>
                  {screen.mdUp && (
                    <div tw="absolute text-sm text-yellow-500 italic ml-2">
                      zu besetzen
                    </div>
                  )}
                  <SearchBar timeVars={refetchVars} />
                </BarArea>
              )}
            </div>
          </div>
        )}
      </TilePrim>
      <JimDialog
        open={showDetails}
        handleClose={() => setShowDetails(false)}
        tw="flex-col space-y-4"
      >
        <div tw="flex flex-col items-center justify-between sm:(justify-start space-x-8)">
          <div tw="font-semibold text-lg">{`${worker.first_name} ${worker.last_name}`}</div>
        </div>
        <div tw="flex justify-between sm:(space-x-8 justify-start)">
          <TextButton>
            <a tw="flex items-center" href={`tel:${worker.phone}`}>
              <PhoneRounded tw="text-xl mr-2" />
            </a>
            {worker.phone}
          </TextButton>
          <TextButton>
            <a tw="flex items-center" href={`mailto:${worker.email}`}>
              <EmailRounded tw="text-xl mr-2" />
            </a>
            {worker.email}
          </TextButton>
        </div>
        <div tw="flex justify-center space-x-2">
          {availsArr.map(avail => (
            <button
              key={`avail-tag-${avail.id}`}
              tw="text-green-500 border border-green-500 px-1 cursor-pointer"
              onClick={e => handleClickOnBar(e, avail)}
              type="button"
            >
              {getTimeTag(avail)}
            </button>
          ))}
        </div>
      </JimDialog>
      <JimDialog
        open={showCreateJobDialog && jobListData}
        handleClose={() => setShowCreateJobDialog(false)}
        title="Job erstellen"
        closable
      >
        <CreateJobForm
          deptList={deptList}
          formVars={formVars}
          setFormVars={setFormVars}
          handleSubmit={handleSubmit}
          minMaxTime={minMaxTime}
        />
      </JimDialog>
      <LoadingDialog
        open={createJobLoading || (showCreateJobDialog && jobListLoading)}
      />
      <JimDialog
        open={showSuccessDialog}
        handleClose={() => setShowSuccessDialog(false)}
        tw="justify-center items-center space-y-2 w-56"
      >
        <div tw="flex text-2xl tracking-wide">Job erstellt!</div>
        <div tw="text-6xl text-green-600">
          <CheckCircleOutlineRounded fontSize="inherit" />
        </div>
      </JimDialog>
      <JimDialog
        open={showOverlapError}
        handleClose={() => setShowOverlapError(false)}
        tw="text-lg"
      >
        <div>{`Leider ist ${worker.first_name[0]}. ${worker.last_name} in der gewählten Zeit schon beschäftigt.`}</div>
        <div>{` Zu folgenden Zeiten ist ${worker.first_name[0]}. ${worker.last_name} nicht mehr verfügbar:`}</div>
        <ul tw="mt-2 pl-6 list-disc">
          {worker.accepted_jobs.map(jobInst => (
            <li>
              {`${formatDE(
                new Date(jobInst.start_time),
                "HH:mm"
              )} bis ${formatDE(new Date(jobInst.end_time), "HH:mm")} Uhr`}
            </li>
          ))}
        </ul>
      </JimDialog>
      {(jobListError || createJobError) && <ErrorDialog />}
    </>
  );
};
AvailDetails.propTypes = {
  availsArr: arrayOf(
    shape({
      start_time: number.isRequired,
      end_time: number.isRequired,
      worker: shape({
        id: string.isRequired,
        first_name: string.isRequired,
        last_name: string.isRequired,
        phone: string.isRequired,
        email: string.isRequired,
        accepted_jobs: arrayOf(
          shape({
            id: string,
            start_time: string,
            end_time: string,
          })
        ),
      }),
    })
  ).isRequired,
  jobAlreadyCreated: bool,
  jimSelected: arrayOf(string),
  setJimSelected: func,
  refetchVars: shape({
    startsBefore: number,
    startsAfter: number,
    endsBefore: number,
    endsAfter: number,
    poolIDs: arrayOf(string),
  }).isRequired,
  facilityID: string.isRequired,
};
AvailDetails.defaultProps = {
  jobAlreadyCreated: false,
  jimSelected: null,
  setJimSelected: undefined,
};

const AvailsList = ({
  startsBefore,
  startsAfter,
  endsBefore,
  endsAfter,
  poolIDs,
  jobAlreadyCreated,
  jimSelected,
  setJimSelected,
  facilityID,
}) => {
  const screen = useContext(ScreenSizeContext);
  const {
    data: listAvailData,
    loading,
    error: listAvailError,
  } = useQuery(LIST_AVAILABILITIES_QUERY, {
    variables: pickBy({
      startsBefore,
      startsAfter,
      endsBefore,
      endsAfter,
      pool: poolIDs,
    }),
    notifyOnNetworkStatusChange: true,
  });
  const [availObj, setAvailObj] = useState({});
  // useEffect(() => {
  //   refetch()
  // }, [startsBefore, startsAfter, endsBefore, endsAfter, poolIDs])

  useEffect(() => {
    const availObjTemp = {};

    if (listAvailData && listAvailData.listAvailabilities) {
      listAvailData.listAvailabilities.forEach(avail => {
        if (jobAlreadyCreated) {
          let overlapping = false;
          avail.worker.accepted_jobs.forEach(jobInst => {
            if (
              areIntervalsOverlapping(
                {
                  start: new Date(startsBefore),
                  end: new Date(endsAfter),
                },
                {
                  start: new Date(jobInst.start_time),
                  end: new Date(jobInst.end_time),
                }
              )
            ) {
              overlapping = true;
            }
          });
          if (!overlapping) {
            if (availObjTemp[avail.worker.id]) {
              availObjTemp[avail.worker.id].push(avail);
            } else {
              availObjTemp[avail.worker.id] = [avail];
            }
          }
        } else if (availObjTemp[avail.worker.id]) {
          availObjTemp[avail.worker.id].push(avail);
        } else {
          availObjTemp[avail.worker.id] = [avail];
        }
      });
      setAvailObj(availObjTemp);
    }
  }, [listAvailData]);

  return (
    <>
      <div tw="flex flex-col pt-4">
        {screen.smUp &&
          listAvailData &&
          listAvailData?.listAvailabilities?.length > 0 && (
            <div tw="flex">
              <div tw="flex w-1/3 lg:w-1/5" />
              <div tw="flex border-l border-black px-2  w-1/3 lg:w-1/5">
                0 Uhr
              </div>
              {screen.smUp && screen.mdDown && (
                <div tw="flex justify-between border-r border-l border-black px-2 w-1/3">
                  <div>12 Uhr</div>
                  <div>24 Uhr</div>
                </div>
              )}
              {screen.lgUp && (
                <>
                  <div tw="flex border-l border-black px-2 w-1/5">6 Uhr</div>
                  <div tw="flex border-l border-black px-2 w-1/5">12 Uhr</div>
                  <div tw="flex justify-between border-r border-l border-black px-2 w-1/5">
                    <div>18 Uhr</div>
                    <div>24 Uhr</div>
                  </div>
                </>
              )}
            </div>
          )}
        {listAvailData &&
          !loading &&
          (listAvailData.listAvailabilities[0] && Object.keys(availObj)[0] ? (
            <>
              {Object.keys(availObj).map(worker => (
                <AvailDetails
                  key={`avail-details-${worker}`}
                  availsArr={availObj[worker]}
                  jobAlreadyCreated={jobAlreadyCreated}
                  jimSelected={jimSelected}
                  setJimSelected={setJimSelected}
                  refetchVars={pickBy({
                    startsBefore,
                    startsAfter,
                    endsBefore,
                    endsAfter,
                    pool: poolIDs,
                  })}
                  facilityID={facilityID}
                />
              ))}
            </>
          ) : (
            <TilePrim tw="sm:col-span-3 lg:col-span-5">
              Leider wurde für den gewählten Zeitraum bisher keine Verfügbarkeit
              gemeldet.
            </TilePrim>
          ))}
        {loading && (
          <TilePrim tw="divide-y-0 space-y-0">
            <div tw="text-lg">
              <Skeleton animation="wave" width="40%" />
            </div>
            <div>
              <Skeleton animation="wave" width="60%" />
            </div>
          </TilePrim>
        )}
      </div>
      {listAvailError && <ErrorDialog />}
    </>
  );
};
AvailsList.propTypes = {
  startsBefore: number,
  startsAfter: number,
  endsBefore: number,
  endsAfter: number,
  poolIDs: arrayOf(string),
  jobAlreadyCreated: bool,
  jimSelected: arrayOf(string),
  setJimSelected: func,
  facilityID: string.isRequired,
};
AvailsList.defaultProps = {
  startsBefore: null,
  startsAfter: null,
  endsBefore: null,
  endsAfter: null,
  poolIDs: null,
  jobAlreadyCreated: false,
  jimSelected: null,
  setJimSelected: undefined,
  // workers: null,
};

export default AvailsList;
