/* eslint-disable react/function-component-definition */
import { gql, useMutation, useQuery } from "@apollo/client";
import { TextField, Skeleton } from "@mui/material";
import { AddRounded, NavigateNextRounded } from "@mui/icons-material";
import { func, shape, string } from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Transition, TransitionGroup } from "react-transition-group";
import tw, { css, styled } from "twin.macro"; // eslint-disable-line no-unused-vars
import JimHelmet from "../../components/JimHelmet";

import {
  ContentWithoutUtilsBar,
  ErrorDialog,
  JimButton,
  LoadingDialog,
  TilePrim,
} from "../../components/util-components";
import { PageTitleContext } from "../../utils/pagetitle-provider";
import { ScreenSizeContext } from "../../utils/screensize-provider";

const LIST_JOBS_QUERY = gql`
  query ListJobs($facility: ID) {
    listJobs(facility: $facility) {
      id
      title
      sugg_descr
      requirements {
        title
      }
    }
  }
`;

const CREATE_JOB = gql`
  mutation CreateJob($title: String!, $facility: ID!, $sugg_descr: String) {
    createJob(title: $title, facility: $facility, sugg_descr: $sugg_descr) {
      id
      title
      facility {
        id
      }
      sugg_descr
    }
  }
`;

const EDIT_JOB = gql`
  mutation EditJob($id: ID!, $title: String, $sugg_descr: String) {
    editJob(id: $id, title: $title, sugg_descr: $sugg_descr) {
      id
      title
      sugg_descr
    }
  }
`;

const JobFieldWrapper = tw.div`flex items-center justify-between p-2 cursor-pointer`;

const TransitionWrapper = styled.div(({ state }) => [
  tw`transition duration-200 transform`,
  state === "entering" && tw`absolute opacity-0 -translate-x-4`,
  state === "entered" && tw`opacity-100`,
  state === "exiting" && tw`opacity-0 translate-x-4`,
  state === "exited" && tw`opacity-0 translate-x-4`,
]);

const JobField = ({ job, jobSelected, setJobSelected }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <JobFieldWrapper
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      onClick={() => setJobSelected(job)}
    >
      <div>
        <div tw="font-semibold">{job.title}</div>
        <div css={[tw`italic`, job.sugg_descr === "" && tw`text-gray-400`]}>
          {job.sugg_descr === "" ? "ohne Beschreibung" : job.sugg_descr}
        </div>
      </div>
      <NavigateNextRounded
        css={[
          tw`opacity-50 transition duration-200`,
          (isActive || jobSelected.id === job.id) &&
            tw`opacity-100 transform translate-x-2`,
        ]}
      />
    </JobFieldWrapper>
  );
};
JobField.propTypes = {
  job: shape({
    id: string,
    title: string,
    sugg_descr: string,
  }).isRequired,
  jobSelected: shape({
    title: string,
    sugg_descr: string,
  }).isRequired,
  setJobSelected: func.isRequired,
};

const JobForm = ({ facilityID, formVars, setFormVars }) => {
  const [createJob, { loading: createJobLoading, error: createJobError }] =
    useMutation(CREATE_JOB);
  const [editJob, { loading: editJobLoading, error: editJobError }] =
    useMutation(EDIT_JOB);

  const handleSubmit = e => {
    e.preventDefault();
    if (formVars.id === "") {
      createJob({
        variables: {
          facility: facilityID,
          title: formVars.title,
          sugg_descr: formVars.sugg_descr,
        },
        refetchQueries: [
          {
            query: LIST_JOBS_QUERY,
            variables: { facility: facilityID },
          },
        ],
      });
    } else {
      editJob({
        variables: formVars,
      });
    }
  };

  return (
    <>
      <form id="new-job-template" tw="py-4 space-y-4" onSubmit={handleSubmit}>
        <TextField
          id="new-job-title"
          tw="w-full"
          label="Titel"
          value={formVars.title}
          variant="outlined"
          required
          onChange={e => {
            setFormVars({ ...formVars, title: e.target.value });
          }}
        />
        <TextField
          id="new-job-description"
          tw="w-full"
          label="Beschreibung"
          multiline
          rows={7}
          value={formVars.sugg_descr}
          variant="outlined"
          onChange={e => {
            setFormVars({ ...formVars, sugg_descr: e.target.value });
          }}
        />
        <JimButton variant="primary" type="submit">
          {formVars.id === "" ? "erstellen" : "speichern"}
        </JimButton>
      </form>
      <LoadingDialog open={createJobLoading || editJobLoading} />
      {(createJobError || editJobError) && <ErrorDialog />}
    </>
  );
};
JobForm.propTypes = {
  facilityID: string.isRequired,
  formVars: shape({
    title: string,
    sugg_descr: string,
  }).isRequired,
  setFormVars: func.isRequired,
};

const JobTemplates = ({ facilityID }) => {
  const screen = useContext(ScreenSizeContext);
  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => setPageTitle("Jobvorlagen"), []);
  const { data, loading } = useQuery(LIST_JOBS_QUERY, {
    variables: { facility: facilityID },
  });
  const emptyJob = {
    id: "",
    title: "",
    sugg_descr: "",
  };
  const [jobSelected, setJobSelected] = useState(emptyJob);
  const [addIsActive, setAddIsActive] = useState(false);

  return (
    <>
      <JimHelmet path={`employer/facility/${facilityID}/job-templates`} />
      <ContentWithoutUtilsBar>
        <div tw="grid grid-cols-1 md:grid-cols-2 gap-4">
          <TilePrim title="Jobvorlagen">
            <div tw="divide-y divide-prim-100 pt-4">
              <JobFieldWrapper
                onMouseEnter={() => setAddIsActive(true)}
                onMouseLeave={() => setAddIsActive(false)}
                onClick={() => setJobSelected(emptyJob)}
              >
                <div>
                  <AddRounded tw="mr-2" />
                  Neue Vorlage
                </div>
                <NavigateNextRounded
                  css={[
                    tw`opacity-50 transition duration-200`,
                    addIsActive && tw`opacity-100 transform translate-x-2`,
                  ]}
                />
              </JobFieldWrapper>
              {data &&
                data.listJobs &&
                data.listJobs.map(job => (
                  <JobField
                    job={job}
                    jobSelected={jobSelected}
                    setJobSelected={setJobSelected}
                  />
                ))}
              {loading && (
                <>
                  <Skeleton width={80} />
                  <Skeleton width={140} />
                </>
              )}
            </div>
          </TilePrim>
          {screen.mdUp && (
            <div>
              <TilePrim
                title={
                  jobSelected.id === ""
                    ? "Neue Jobvorlage"
                    : "Vorlage bearbeiten"
                }
              >
                <TransitionGroup>
                  <Transition key={jobSelected.id} timeout={200}>
                    {state => (
                      <TransitionWrapper state={state}>
                        <JobForm
                          facilityID={facilityID}
                          formVars={jobSelected}
                          setFormVars={setJobSelected}
                        />
                      </TransitionWrapper>
                    )}
                  </Transition>
                </TransitionGroup>
              </TilePrim>
            </div>
          )}
        </div>
      </ContentWithoutUtilsBar>
    </>
  );
};
JobTemplates.propTypes = {
  facilityID: string.isRequired,
};

export default JobTemplates;
