/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import { gql, useMutation } from "@apollo/client";
import { CheckCircleOutlineRounded } from "@mui/icons-material";
import { addHours } from "date-fns";
import { string } from "prop-types";

import {
  ContentWithoutUtilsBar,
  ErrorDialog,
  JimDialog,
  JimLink,
  LoadingDialog,
  TilePrim,
} from "../components/util-components";
import CreateJobForm from "../components/page-components/create-job/CreateJobForm";
import { payPerHour } from "../utils/global-consts";
import { PageTitleContext } from "../utils/pagetitle-provider";
import withRoles from "../utils/with-roles";
import { UserRolesContext } from "../utils/userroles-provider";
import JimHelmet from "../components/JimHelmet";

const CREATE_JOBINSTANCES_MUTATION = gql`
  mutation CreateJobInstances($jobInstances: [JobInstanceInput]!) {
    createJobInstances(jobInstances: $jobInstances) {
      id
      job {
        id
        title
      }
      status
      department {
        id
        name
      }
      start_time
      end_time
      length
      pay
      worker {
        id
        first_name
        last_name
      }
      instant_book
      pool {
        id
      }
    }
  }
`;

// const DEPT_JOBINSTANCES_QUERY = gql`
//   query ListDeptJobInstances($department: [ID], $startsAfter: Float) {
//     listJobInstances(department: $department, startsAfter: $startsAfter) {
//       id
//       job {
//         id
//         title
//       }
//       status
//       department {
//         name
//       }
//       start_time
//       end_time
//       length
//       pay
//       worker {
//         first_name
//         last_name
//         phone
//         email
//       }
//     }
//   }
// `;

const rightNow = new Date();
const tomorrow = new Date(rightNow);
tomorrow.setDate(tomorrow.getDate() + 1);
tomorrow.setHours(7, 0, 0, 0);

const SuccessMsg = tw.div`flex text-2xl tracking-wide`;

const CreateJobFacility = ({ facilityID }) => {
  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => setPageTitle("Job erstellen"), []);
  const { userFacilitiesByID } = useContext(UserRolesContext);
  const [
    createJobInstances,
    { loading: createJobLoading, error: createJobError },
  ] = useMutation(CREATE_JOBINSTANCES_MUTATION);
  const [formVars, setFormVars] = useState({
    dept: userFacilitiesByID[facilityID].departments[0]
      ? userFacilitiesByID[facilityID].departments[0].id
      : null,
    pool: null,
    job_id: null,
    title: null,
    description: "Beschreibung...",
    start_time: tomorrow.getTime(),
    end_time: addHours(tomorrow, 2).getTime(),
    num_req_workers: 1,
    instant_book: false,
    recurring_timestamps: [tomorrow.getTime()],
  });
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const handleSubmit = jobID => {
    const lengthInMS = formVars.end_time - formVars.start_time;
    const lengthInHours = lengthInMS / 3600000;
    const promiseArr = [];
    formVars.recurring_timestamps.forEach(timestamp => {
      const newJobArr2 = [];
      for (let i = 0; i < formVars.num_req_workers; i += 1) {
        newJobArr2.push({
          department: formVars.dept,
          start_time: timestamp,
          end_time: timestamp + lengthInMS,
          job: jobID,
          pay: Math.round(lengthInHours * payPerHour * 100) / 100,
          length: lengthInHours,
          description: formVars.description,
          pool: formVars.pool,
          instant_book: formVars.instant_book,
        });
      }
      promiseArr.push(
        createJobInstances({
          variables: { jobInstances: newJobArr2 },
        })
      );
    });

    Promise.all(promiseArr).then(() => setShowSuccessDialog(true));
  };

  return (
    <>
      <JimHelmet path={`employer/create-job/${facilityID}`} />
      <ContentWithoutUtilsBar>
        {userFacilitiesByID[facilityID].pools[0] &&
        userFacilitiesByID[facilityID].departments[0] ? (
          <TilePrim title="Erstelle einen Job">
            <CreateJobForm
              deptList={userFacilitiesByID[facilityID].departments}
              formVars={formVars}
              setFormVars={setFormVars}
              handleSubmit={handleSubmit}
              withDate
              withNumWorkers
            />
          </TilePrim>
        ) : (
          <>
            {!userFacilitiesByID[facilityID].pools[0] && (
              <TilePrim>
                <div>
                  Scheinbar hast du für die Einrichtung{" "}
                  <b>{userFacilitiesByID[facilityID].name}</b> noch keinen Pool
                  eingerichtet.
                </div>
                <div>
                  Um Einsätze erstellen zu können, musst du erst einen Pool
                  erstellen.
                </div>
                <JimLink
                  tw="self-start"
                  to={`/employer/${facilityID}/facility-settings/pools`}
                >
                  zu meinen Pools
                </JimLink>
              </TilePrim>
            )}
            {!userFacilitiesByID[facilityID].departments[0] && (
              <TilePrim>
                <div>
                  Scheinbar hast du für die Einrichtung{" "}
                  <b>{userFacilitiesByID[facilityID].name}</b> noch keine
                  Abteilung erstellt.
                </div>
                <div>
                  Um Einsätze erstellen zu können, musst du erst eine Abteilung
                  erstellen.
                </div>
                <JimLink
                  tw="self-start"
                  to={`/employer/${facilityID}/facility-settings/edit`}
                >
                  zu meiner Einrichtung
                </JimLink>
              </TilePrim>
            )}
          </>
        )}
        <JimDialog
          open={showSuccessDialog}
          handleClose={() => setShowSuccessDialog(false)}
          tw="flex flex-col justify-center items-center space-y-4 w-56"
        >
          <SuccessMsg>Job erstellt!</SuccessMsg>
          <div tw="text-6xl text-green-600">
            <CheckCircleOutlineRounded fontSize="inherit" />
          </div>
        </JimDialog>
        <LoadingDialog open={createJobLoading} />
        {createJobError && <ErrorDialog />}
      </ContentWithoutUtilsBar>
    </>
  );
};
CreateJobFacility.propTypes = {
  facilityID: string.isRequired,
};

export default withRoles(CreateJobFacility, "EMPLOYER");
