/* eslint-disable camelcase, react/function-component-definition */
import { gql, useMutation } from "@apollo/client";
import { TextField } from "@mui/material";
import { shape, string } from "prop-types";
import React, { useState } from "react";
import tw from "twin.macro";
import { GET_USER_ROLES } from "../../../utils/queries";
import {
  ErrorDialog,
  JimButton,
  JimDialog,
  LoadingDialog,
  TextButton,
} from "../../util-components";

const REMOVE_ADMIN = gql`
  mutation RemoveFacilityAdmin($facility: ID!, $user: ID!) {
    removeFacilityAdmin(facility: $facility, user: $user) {
      id
      users {
        role
        user {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

const REMOVE_WORKER = gql`
  mutation RemoveFacilityWorker($facility: ID!, $user: ID!) {
    removeFacilityWorker(facility: $facility, user: $user) {
      id
      users {
        role
        user {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

const ContentAreaWrapper = tw.div`flex flex-col p-4 space-y-2`;

const UserListItem = ({ user, userRole, facilityID }) => {
  const { id, first_name, last_name } = user;
  const [
    removeFacilityWorker,
    { loading: loadingRemoveWorker, error: errorRemoveWorker },
  ] = useMutation(REMOVE_WORKER);
  const [
    removeFacilityAdmin,
    { loading: loadingRemoveAdmin, error: errorRemoveAdmin },
  ] = useMutation(REMOVE_ADMIN);
  const [awaitConfirmDel, setAwaitConfirmDel] = useState(false);
  const [confirmDelText, setConfirmDelText] = useState("");

  const handleRemove = e => {
    e.stopPropagation();

    if (userRole === "ADMIN") {
      removeFacilityAdmin({
        variables: { facility: facilityID, user: id },
        refetchQueries: [
          {
            query: GET_USER_ROLES,
          },
        ],
      }).then(() => {
        setAwaitConfirmDel(false);
      });
    } else {
      removeFacilityWorker({
        variables: { facility: facilityID, user: id },
        refetchQueries: [
          {
            query: GET_USER_ROLES,
          },
        ],
      }).then(() => {
        setAwaitConfirmDel(false);
      });
    }
  };

  const handleClickOnDel = e => {
    e.stopPropagation();
    setAwaitConfirmDel(true);
  };

  return (
    <ContentAreaWrapper>
      <div tw="flex items-center justify-between">
        <div tw="font-semibold text-lg">{`${first_name} ${last_name}`}</div>

        {userRole === "CREATOR" ? (
          <div tw="text-sm italic opacity-80">Ersteller*in</div>
        ) : (
          <TextButton onClick={handleClickOnDel}>entfernen</TextButton>
        )}
      </div>
      <JimDialog
        open={awaitConfirmDel}
        handleClose={() => setAwaitConfirmDel(false)}
        tw="items-center w-96 text-center"
      >
        <div tw="text-lg">
          <strong>{`${first_name} ${last_name}`}</strong> wirklich löschen?
        </div>
        <div tw="text-red-500">
          {`${first_name} ${last_name} wird alle Rechte zur Verwaltung der Einrichtung verlieren. ${first_name} ${last_name} wird keine Einsätze mehr erstellen können, keine Pools verwalten etc.`}
        </div>
        <div tw="text-sm pt-2">
          Bitte gib zur Bestätigung den Nachnamen der Nutzerin/des Nutzers ein
        </div>
        <TextField
          variant="outlined"
          value={confirmDelText}
          onChange={e => setConfirmDelText(e.target.value)}
        />
        <div tw="flex items-center space-x-4">
          <TextButton onClick={() => setAwaitConfirmDel(false)}>
            abbr.
          </TextButton>
          <JimButton
            variant="cancel"
            onClick={handleRemove}
            disabled={confirmDelText !== last_name}
          >
            ja, entfernen
          </JimButton>
        </div>
      </JimDialog>
      <LoadingDialog open={loadingRemoveWorker || loadingRemoveAdmin} />
      {(errorRemoveWorker || errorRemoveAdmin) && <ErrorDialog />}
    </ContentAreaWrapper>
  );
};
UserListItem.propTypes = {
  user: shape({
    id: string,
    first_name: string,
    last_name: string,
  }).isRequired,
  userRole: string.isRequired,
  facilityID: string.isRequired,
};

export default UserListItem;
