/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro"; // eslint-disable-line no-unused-vars
import { gql, useQuery } from "@apollo/client";
import { string } from "prop-types";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import { TextField } from "@mui/material";

import { PageTitleContext } from "../utils/pagetitle-provider";
import withRoles from "../utils/with-roles";
import AvailsList from "../components/AvailsList";
import {
  ContentWithUtilsBar,
  ErrorDialog,
  JimLink,
  LoadingTile,
  TilePrim,
} from "../components/util-components";
import { UserRolesContext } from "../utils/userroles-provider";
import JimHelmet from "../components/JimHelmet";
import UtilsBar from "../layout/UtilsBar";

const LIST_POOLS_QUERY = gql`
  query ListPools($facility: [ID]) {
    listPools(facility: $facility) {
      id
      title
      description
      workers {
        id
        first_name
        last_name
        phone
        email
      }
    }
  }
`;

const Availabilities = ({ facilityID }) => {
  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => setPageTitle("Verfügbarkeiten"), []);
  const { userFacilitiesByID } = useContext(UserRolesContext);
  const [poolsSel, setPoolsSel] = useState(
    userFacilitiesByID[facilityID].pools.map(pool => pool.id)
  );
  const [dateSelected, setDateSelected] = useState(dayjs().startOf("day"));
  const {
    data: listPoolsData,
    loading: listPoolsLoading,
    error: listPoolsError,
  } = useQuery(LIST_POOLS_QUERY, {
    variables: { facility: [facilityID] },
  });

  // const setIDs = poolCurr => {
  //   let poolIDsArr = []
  //   if (poolCurr.id === "1") {
  //     poolIDsArr = listPoolsData.listPools.map(pool => pool.id)
  //   } else {
  //     poolIDsArr = [poolCurr.id]
  //   }
  //   setPoolIDs(poolIDsArr)
  // }

  // useEffect(() => {
  //   if (listPoolsData && listPoolsData.listPools[0]) {
  //     setIDs(poolSelected)
  //   }
  // }, [listPoolsData])

  const handleDateChange = date => {
    setDateSelected(date);
  };

  return (
    <>
      <JimHelmet path={`employer/availabilities/${facilityID}`} />
      <UtilsBar
        facilityID={facilityID}
        showPools
        setPoolsSel={setPoolsSel}
        customSlot={
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              label="Datum"
              renderInput={params => <TextField fullWidth {...params} />}
              value={dateSelected}
              onChange={handleDateChange}
              minDate={dayjs()}
              inputFormat="ddd, DD. MMM YYYY"
              closeOnSelect
            />
          </LocalizationProvider>
        }
      />
      <ContentWithUtilsBar>
        <AvailsList
          startsAfter={dateSelected.startOf("day").valueOf()}
          endsBefore={dateSelected.endOf("day").valueOf()}
          poolIDs={poolsSel}
          facilityID={facilityID}
        />
        {listPoolsData &&
          listPoolsData.listPools &&
          !listPoolsData.listPools[0] && (
            <TilePrim>
              <div>
                Scheinbar hast du für die Einrichtung{" "}
                <b>{userFacilitiesByID[facilityID].name}</b> noch keinen Pool
                eingerichtet.
              </div>
              <div>
                Um nach verfügbaren JiMs suchen zu können, musst du erst einen
                Pool erstellen.
              </div>
              <JimLink
                tw="self-start"
                to={`/employer/${facilityID}/facility-settings/pools`}
              >
                zu meinen Pools
              </JimLink>
            </TilePrim>
          )}
        {listPoolsLoading && <LoadingTile />}
        {listPoolsError && <ErrorDialog />}
      </ContentWithUtilsBar>
    </>
  );
};
Availabilities.propTypes = {
  facilityID: string.isRequired,
};

export default withRoles(Availabilities, "EMPLOYER");
