/* eslint-disable camelcase, react/function-component-definition */
import { gql, useMutation } from "@apollo/client";
import { TextField } from "@mui/material";
import { shape, string } from "prop-types";
import React, { useState } from "react";
import tw from "twin.macro";
import { GET_USER_ROLES } from "../../../utils/queries";
import {
  ErrorDialog,
  JimButton,
  JimDialog,
  LoadingDialog,
  TextButton,
} from "../../util-components";

const EDIT_DEPT = gql`
  mutation EditDepartment($departmentID: ID!, $name: String) {
    editDepartment(departmentID: $departmentID, name: $name) {
      id
      name
    }
  }
`;

const DELETE_DEPT = gql`
  mutation DeleteDepartment($departmentID: ID!) {
    deleteDepartment(departmentID: $departmentID) {
      id
    }
  }
`;

const ContentAreaWrapper = tw.div`flex flex-col p-4 space-y-2`;

const DeptListItem = ({ dept }) => {
  const { id, name } = dept;
  // const { userID } = useContext(UserRolesContext)
  const [deleteDept, { loading: deleteLoading, error: deleteError }] =
    useMutation(DELETE_DEPT);
  const [editDept, { loading: editLoading, error: editError }] =
    useMutation(EDIT_DEPT);
  const [awaitConfirmDel, setAwaitConfirmDel] = useState(false);
  const [confirmDelText, setConfirmDelText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [deptNameNew, setDeptNameNew] = useState(name);

  const handleEditToggle = () => {
    if (isEditing) {
      setIsEditing(false);
      setDeptNameNew(name);
    } else {
      setIsEditing(true);
    }
  };

  const handleSave = e => {
    e.preventDefault();
    setIsEditing(false);
    editDept({
      variables: { departmentID: id, name: deptNameNew },
      refetchQueries: [
        {
          query: GET_USER_ROLES,
        },
      ],
    });
  };

  const handleDeleteDept = e => {
    e.stopPropagation();
    deleteDept({
      variables: { departmentID: id },
      refetchQueries: [
        {
          query: GET_USER_ROLES,
        },
      ],
    }).then(() => {
      setAwaitConfirmDel(false);
    });
  };

  const handleClickOnDel = e => {
    e.stopPropagation();
    setAwaitConfirmDel(true);
  };

  return (
    <ContentAreaWrapper>
      <div tw="flex items-center justify-between">
        {isEditing ? (
          <>
            <TextField
              id={`edit-dept-name-${id}`}
              value={deptNameNew}
              variant="outlined"
              onChange={e => setDeptNameNew(e.target.value)}
            />
            <div tw="flex space-x-2">
              <JimButton variant="primary" onClick={handleSave}>
                speichern
              </JimButton>
              <TextButton onClick={handleEditToggle}>abbr.</TextButton>
            </div>
          </>
        ) : (
          <>
            <div tw="font-semibold text-lg">{name}</div>
            <div tw="flex space-x-2">
              <TextButton onClick={handleEditToggle}>bearb.</TextButton>
              <TextButton onClick={handleClickOnDel}>löschen</TextButton>
            </div>
          </>
        )}
      </div>
      <JimDialog
        open={awaitConfirmDel}
        handleClose={() => setAwaitConfirmDel(false)}
        tw="items-center w-96 text-center"
      >
        <div tw="text-lg">
          Abteilung <strong>{name}</strong> wirklich löschen?
        </div>
        <div tw="text-red-500">
          Wenn du eine Abteilung löscht, hast du keine Übersicht mehr über die
          mit dieser Abteilung verknüpften anstehenden und abgeschlossenen
          Einsätze.
        </div>
        <div>
          Falls du diese Abteilung gerade erst erstellt hast und sie noch nicht
          für die Erstellung von Einsätzen oder anderweitig verwendet hast, hat
          das Löschen der Abteilung keine weiteren Konsequenzen.
        </div>
        <div tw="text-sm pt-2">
          Bitte gib zur Bestätigung den Namen der Abteilung ein
        </div>
        <TextField
          variant="outlined"
          value={confirmDelText}
          onChange={e => setConfirmDelText(e.target.value)}
        />
        <div tw="flex items-center space-x-4">
          <TextButton onClick={() => setAwaitConfirmDel(false)}>
            abbr.
          </TextButton>
          <JimButton
            variant="cancel"
            onClick={handleDeleteDept}
            disabled={confirmDelText !== name}
          >
            ja, löschen
          </JimButton>
        </div>
      </JimDialog>
      <LoadingDialog open={deleteLoading || editLoading} />
      {(deleteError || editError) && <ErrorDialog />}
    </ContentAreaWrapper>
  );
};
DeptListItem.propTypes = {
  dept: shape({
    id: string,
    name: string,
  }).isRequired,
};

export default DeptListItem;
