/* eslint-disable camelcase, react/function-component-definition */
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { TextField, MenuItem, Select } from "@mui/material";
import { AddRounded } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro"; // eslint-disable-line no-unused-vars
import { string } from "prop-types";

import {
  ContentWithoutUtilsBar,
  ErrorDialog,
  JimButton,
  JimDialog,
  LoadingDialog,
  TextButton,
  TilePrim,
} from "../../components/util-components";
import withRoles from "../../utils/with-roles";
import { PageTitleContext } from "../../utils/pagetitle-provider";
import { UserRolesContext } from "../../utils/userroles-provider";
import { GET_USER_ROLES } from "../../utils/queries";
import JimHelmet from "../../components/JimHelmet";
import ContentArea from "../../components/page-components/edit-facility/ContentArea";
import DeptListItem from "../../components/page-components/edit-facility/DeptListItem";
import UserListItem from "../../components/page-components/edit-facility/UserListItem";
import SubscriptionDetailsArea from "../../components/page-components/edit-facility/SubscriptionDetailsArea";

const CREATE_DEPT = gql`
  mutation CreateDepartment($name: String!, $facility: ID!) {
    createDepartment(name: $name, facility: $facility) {
      id
      name
      facility {
        id
      }
    }
  }
`;

const ADD_ADMIN = gql`
  mutation AddFacilityAdmin($facility: ID!, $user: ID!) {
    addFacilityAdmin(facility: $facility, user: $user) {
      id
      users {
        role
        user {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

const FIND_USER_BY_EMAIL = gql`
  query FindUserByEmail($email: String) {
    listUsers(email: $email) {
      id
    }
  }
`;

// const VerifiedTag = styled.div(({ status }) => [
//   tw`flex items-center text-sm font-semibold border-2 border-yellow-500 text-yellow-500 rounded-md px-1`,
//   status === "ACTIVE" && tw`border-green-500 text-green-500`,
// ])

const EditFacility = ({ facilityID }) => {
  const { userFacilitiesByID } = useContext(UserRolesContext);
  const facility = userFacilitiesByID[facilityID];
  const adminsList = facility.users
    .filter(userObj => userObj.role === "ADMIN")
    .map(adminObj => adminObj.user);
  const workersList = facility.users
    .filter(userObj => userObj.role === "WORKER")
    .map(workerObj => workerObj.user);
  const workersOnlyList = workersList.filter(
    workerObj => !adminsList.map(adminObj => adminObj.id).includes(workerObj.id)
  );

  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => {
    setPageTitle(`${facility.name} verwalten`);
  }, []);

  const [createDept, { loading: createLoading, error: createError }] =
    useMutation(CREATE_DEPT);
  const [isCreating, setIsCreating] = useState(false);
  const [newDeptName, setNewDeptName] = useState("");

  const [addFacilityAdmin, { loading: addAdminLoading, error: addAdminError }] =
    useMutation(ADD_ADMIN);
  const [isAddingAdmin, setIsAddingAdmin] = useState(false);

  const [
    findUserByEmail,
    { data: userFromEmail, loading: userFromEmailLoading },
  ] = useLazyQuery(FIND_USER_BY_EMAIL);
  const [addAdminEmail, setAddAdminEmail] = useState(null);
  const [showFindUserError, setShowFindUserError] = useState(false);

  const handleSave = e => {
    e.preventDefault();
    createDept({
      variables: { name: newDeptName, facility: facilityID },
      refetchQueries: [
        {
          query: GET_USER_ROLES,
        },
      ],
    }).then(() => setIsCreating(false));
  };

  useEffect(() => {
    if (userFromEmail && userFromEmail.listUsers.length === 1) {
      if (isAddingAdmin) {
        addFacilityAdmin({
          variables: {
            facility: facilityID,
            user: userFromEmail.listUsers[0].id,
          },
          refetchQueries: [
            {
              query: GET_USER_ROLES,
            },
          ],
        }).then(() => setIsAddingAdmin(false));
      }
    } else if (userFromEmail && userFromEmail.listUsers.length !== 1) {
      setShowFindUserError(true);
    }
  }, [userFromEmail]);

  const handleAddAdmin = e => {
    e.preventDefault();
    e.stopPropagation();

    if (addAdminEmail !== null) {
      findUserByEmail({ variables: { email: addAdminEmail } });
    }
  };

  return (
    <>
      <JimHelmet path={`employer/${facilityID}/facility-settings/edit`} />
      <ContentWithoutUtilsBar>
        <div tw="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div tw="flex flex-col space-y-4">
            <TilePrim tw="m-0">
              <div tw="flex flex-col divide-y divide-prim-100">
                <ContentArea
                  title="Name"
                  items={["name"]}
                  widthArr={[100]}
                  facilityData={facility}
                />
                <ContentArea
                  title="Adresse"
                  items={["street", "number", "zip", "city"]}
                  widthArr={[70, 30, 40, 60]}
                  facilityData={facility}
                />
                {/* <ContentArea
              title="Telefonnummer"
              items={["phone"]}
              widthArr={[100]}
              facilityData={facilityData}
            /> */}
              </div>
            </TilePrim>
            <SubscriptionDetailsArea facilityID={facilityID} />
          </div>
          <div tw="flex flex-col space-y-4">
            <TilePrim title="Abteilungen" tw="m-0">
              <div tw="flex flex-col divide-y divide-prim-100">
                {facility &&
                  facility.departments.map(dept => (
                    <DeptListItem key={`dept-${dept.id}`} dept={dept} />
                  ))}
                <div tw="flex items-center justify-between p-4">
                  {isCreating ? (
                    <div tw=" flex flex-col space-y-2">
                      <TextField
                        id="edit-new-dept-name"
                        value={newDeptName}
                        label="Name"
                        variant="outlined"
                        onChange={e => setNewDeptName(e.target.value)}
                      />
                      <div tw="flex space-x-2">
                        <JimButton
                          variant="primary"
                          onClick={handleSave}
                          disabled={newDeptName.trim() === ""}
                        >
                          speichern
                        </JimButton>
                        <TextButton onClick={() => setIsCreating(false)}>
                          abbr.
                        </TextButton>
                      </div>
                    </div>
                  ) : (
                    <div tw="flex space-x-2">
                      <TextButton onClick={() => setIsCreating(true)}>
                        <AddRounded tw="mr-1 text-xl" />
                        Abteilung hinzufügen
                      </TextButton>
                    </div>
                  )}
                </div>
              </div>
            </TilePrim>
            <TilePrim title="Admins" tw="m-0 max-h-96">
              <div tw="flex flex-col divide-y divide-prim-100">
                {facility &&
                  facility.users.map(
                    userObj =>
                      userObj.role === "CREATOR" && (
                        <UserListItem
                          key={`admin-${userObj.user.id}`}
                          user={userObj.user}
                          userRole="CREATOR"
                          facilityID={facilityID}
                        />
                      )
                  )}
                {facility &&
                  facility.users.map(
                    userObj =>
                      userObj.role === "ADMIN" && (
                        <UserListItem
                          key={`admin-${userObj.user.id}`}
                          user={userObj.user}
                          userRole="ADMIN"
                          facilityID={facilityID}
                        />
                      )
                  )}
                <div tw="flex items-center justify-between p-4">
                  {isAddingAdmin ? (
                    <form
                      tw=" flex flex-col space-y-2 w-full"
                      onSubmit={handleAddAdmin}
                    >
                      <Select
                        id="select-admin-email"
                        value={addAdminEmail}
                        onChange={({ target }) =>
                          setAddAdminEmail(target.value)
                        }
                        fullWidth
                        renderValue={value =>
                          `${value.first_name} ${value.last_name}`
                        }
                      >
                        {workersOnlyList.length > 0 ? (
                          workersOnlyList.map(userObj => (
                            <MenuItem
                              tw="flex flex-col items-start"
                              key={userObj.id}
                              value={userObj}
                            >
                              <span>{`${userObj.first_name} ${userObj.last_name}`}</span>
                              <span tw="italic text-sm">{userObj.email}</span>
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value={null}>
                            <span tw="italic text-sm">
                              keine Nutzer vorhanden
                            </span>
                          </MenuItem>
                        )}
                      </Select>
                      <div tw="flex space-x-2">
                        <JimButton
                          variant="primary"
                          type="submit"
                          disabled={!addAdminEmail}
                        >
                          hinzufügen
                        </JimButton>
                        <TextButton onClick={() => setIsAddingAdmin(false)}>
                          abbr.
                        </TextButton>
                      </div>
                    </form>
                  ) : (
                    <div tw="flex space-x-2">
                      <TextButton onClick={() => setIsAddingAdmin(true)}>
                        <AddRounded tw="mr-1 text-xl" />
                        Admin hinzufügen
                      </TextButton>
                    </div>
                  )}
                </div>
              </div>
            </TilePrim>
          </div>
        </div>
        <JimDialog
          open={showFindUserError}
          handleClose={() => setShowFindUserError(false)}
        >
          <div>
            Leider konnten wir anhand deiner Eingabe keinen Nutzer finden. Bitte
            überprüfe die eingegebene Email-Adresse.
          </div>
        </JimDialog>
        <LoadingDialog
          open={
            createLoading ||
            // addWorkerLoading ||
            addAdminLoading ||
            userFromEmailLoading
          }
        />
        {(createError ||
          // addWorkerError ||
          addAdminError) && <ErrorDialog />}
      </ContentWithoutUtilsBar>
    </>
  );
};
EditFacility.propTypes = {
  facilityID: string.isRequired,
};

export default withRoles(EditFacility, "EMPLOYER");
